<template>
    <div style="background-color: #f7f8fa; height: 100%;">
        <div style="width:100%; height:220px; flex-flow: column; display: flex;align-items: center;justify-content: center;">
            <van-image
                    width="120"
                    height="120"
                    src="https://sqtech.oss-cn-beijing.aliyuncs.com/Logo.png"
            />
            <div style="margin-top: 10px;">
                分销系统
            </div>
        </div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                用户登录
            </div>
            <van-form>
                <van-field
                        v-model="phone"
                        name="手机号"
                        type="tel"
                        label="手机号"
                        placeholder="手机号"
                        :rules="[{ required: true, message: '请填写手机号' }]"
                />
                <van-field
                        v-model="pwd"
                        name="登录密码"
                        label="登录密码"
                        type="password"
                        placeholder="请输入登录密码"
                        :rules="[{ required: true, message: '请填写登录密码' }]"
                />
            </van-form>

        </div>
        <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit"  @click="onSubmit">
                用户登录
            </van-button>
        </div>

        <div  style="font-size: 14px;margin-top: 10px; color: #1296db; background-color: #fff; padding-top: 5px; display: flex; justify-content: flex-start;">
            <span @click="changepwd" style="flex: 1;margin-left: 20px;">修改密码 </span>
            <span @click="regnewuser" style="width:100px;">新用户注册 </span>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Picker } from 'vant';
    import { Popup } from 'vant';
    import { Notify } from 'vant';
    import { Image as VanImage } from 'vant';
    Vue.use(VanImage);
    Vue.use(Notify);
    Vue.use(Popup);
    Vue.use(Picker);
    Vue.use(Field);
    Vue.use(Form);
    var that
    export default {
        name: "Regedit",
        data() {
            return {
                phone: '',
                sms: '',
                userimg: '',
                usernick: '',
                childnick: '',
                jsname: '',
                value: '',
                jsdes: '',
                pwd: '',
                sf: '',
                parentname: '',
                sflist: [],
                childname: '',
                typeid: '',
                yzmabled: false,
                minDate: new Date(2000, 9, 1),
                maxDate: new Date(2020, 10, 1),
                currentDate: new Date(),
                typevalue: '',
                smscode: '',
                teacher_type_list: [],
                cbirthday: '',
                teacher_type_idlist: [],
                showPicker: false,
                btntxt: '发送验证码'
            };
        },
        mounted: function () {
            that = this
            document.title = '用户登录'
        },
        methods: {
            changepwd () {
                that.$router.push('/ChangePwd')
            },
            regnewuser () {
                that.$router.push('/RegeditUser')
            },
            onSubmit(values) {
                window.console.log('submit', values);
                if (this.phone !== '' && this.pwd !== '') {
                    var qstr = {
                        whereinfo: " phone='" + that.phone + "' and loginpwd='" + that.pwd + "'",
                        limit: '',
                        sortinfo: ''
                    }

                    that.$http({
                        url: 'https://bcapi.sqnetsoft.cn/mlogin',
                        method: 'post',
                        data: qstr,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            window.console.log(res.data)
                            if (res.data.length >= 1) {
                                var tmpdata = res.data[0]
                                localStorage.setItem('userid', tmpdata.userid)
                                localStorage.setItem('loginid', tmpdata.userid)
                                localStorage.setItem('userphone', tmpdata.phone)
                                localStorage.setItem('loginname', tmpdata.realname)
                                localStorage.setItem('openid', tmpdata.openid)
                                localStorage.setItem('mpermission', tmpdata.mpermission)
                                that.$router.push({path: 'MainPage'})
                            } else {
                                Notify({ type: 'warning', message: '账号错误！' });
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    Notify({ type: 'warning', message: '信息不完整，请检查' });
                }

            }
        }
    }
</script>

<style scoped>
    body {
        min-width: 1100px;
        margin: 0;
        overflow-x: auto;
        color: #323233;
        font-size: 16px;
        font-family: PingFang SC, 'Helvetica Neue', Arial, sans-serif;
        background-color: #f7f8fa;
        -webkit-font-smoothing: antialiased;
    }
    .van-doc-demo-block:first-of-type .van-doc-demo-block__title {
        padding-top: 20px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 32px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
