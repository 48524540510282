<template>
  <div id="app">
    <transition>
        <router-view></router-view>
    </transition>
  </div>
</template>

<script>
    import Vue from 'vue';
    import { Tabbar, TabbarItem } from 'vant';
    Vue.use(Tabbar).use(TabbarItem);
    export default {
        name: 'app',
        data() {
            return {
                icon: {
                    main: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dfsd/icons/%E9%A6%96%E9%A1%B5.png',
                    mainsel: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dfsd/icons/s_%E9%A6%96%E9%A1%B5.png',
                    zx: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dfsd/icons/%E8%B5%84%E8%AE%AF.png',
                    zxsel: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dfsd/icons/s_%E8%B5%84%E8%AE%AF.png',
                    gl: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dfsd/icons/%E8%AF%BE%E7%A8%8B.png',
                    glsel: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dfsd/icons/s_%E8%AF%BE%E7%A8%8B.png',
                    my: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dfsd/icons/%E6%88%91%E7%9A%84.png',
                    mysel: 'https://sqpro.oss-cn-beijing.aliyuncs.com/dfsd/icons/s_%E6%88%91%E7%9A%84.png'
                }
            }
        }
    }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
      padding: 0;
      margin: 0;
    background-size: cover;
    height: 100%;
    background-size: 100% 100%;
    background-attachment: fixed;
  }
</style>
