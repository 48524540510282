<template>
    <div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                登录人员选择
            </div>
            <van-cell-group v-for="(item,index) in jllist" :key="index">
                <van-cell is-link @click="login(item.id,item.jlname)" icon="user-o">{{item.jlname}}</van-cell>
            </van-cell-group>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Toast } from 'vant'
    Vue.use(Toast);
    var that
    export default {
        name: "SFWay",
        data () {
            return {
                jllist: []
            }
        },
        mounted() {
            that = this
            that.getjl()
        },
        methods: {
            getjl () {
                var qstr = 'id > 0'
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryjl',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            that.jllist = res.data
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            login(id,name) {
                localStorage.setItem('loginid',id)
                localStorage.setItem('loginname',name)
                that.$router.push({path: 'MainPage'})
            }
        }
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
