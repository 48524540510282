<template>
    <div>
        <van-nav-bar
                :title="'我的评价(' + pjrq + ')'"
                left-text="返回"
                right-text="日期选择"
                left-arrow
                @click-left="returnyw"
                @click-right="show = true"
        />
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Notify } from 'vant';
    import axios from 'axios'
    Vue.prototype.$http = axios
    Vue.use(Notify);
    var that
    export default {
        name: "MyJY",
        data() {
            return {
                rqvalue: '',
                sjstartvalue: '',
                sjendvalue: '',
                showCalendar: false,
                showPicker1: false,
                showPicker2: false,
                qjdes: ''
            };
        },
        mounted: function () {
            that = this
            window.console.log(that)
            that.$options.methods.checkuserid()
        },
        methods: {
            checkuserid: function () {
                window.console.log('======= 正在检测userid  ========')
                var tmpdata = JSON.parse(localStorage.getItem('userinfo'))
                that.userimg = tmpdata.headimgurl
                that.usernick = tmpdata.nickname
                if (localStorage.getItem('UserId') === null || localStorage.getItem('UserId') === '0') {
                    window.console.log('======= userid 获取失败  ========')
                    that.$options.methods.checkuser()
                } else {
                    window.console.log('======= userid 已获取  ========')
                    Notify({ type: 'success', message: '您好,' + localStorage.getItem('UserName') });
                }
            },
            checkuser: function () {
                // 依照openid来查看有没有对应用户
                var qstr1 = "openid = '" + localStorage.getItem('openid') + "'"
                that.$http({
                    url: 'https://api.sqnetsoft.cn/member/gbmu',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr1, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data.toString() !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                if (tmpdata[0].id === 0) {
                                    window.console.log('======= userid 获取失败  ========')
                                    localStorage.setItem('UserId',0)
                                    that.$router.push({path: 'Regedit'})
                                } else {
                                    window.console.log('======= 已获取userid  ========')
                                    localStorage.setItem("UserId",tmpdata[0].id.Int64)
                                    localStorage.setItem("UserName",tmpdata[0].parentname.String)
                                    Notify({ type: 'success', message: '您好,' + tmpdata[0].parentname.String });
                                }
                            } else {
                                window.console.log('======= userid 获取失败  ========')
                                localStorage.setItem('UserId',0)
                                that.$router.push({path: 'Regedit'})
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })

            }
        }
    }
</script>

<style scoped>

</style>