import { render, staticRenderFns } from "./TaskFx.vue?vue&type=template&id=9a801e30&scoped=true&"
import script from "./TaskFx.vue?vue&type=script&lang=js&"
export * from "./TaskFx.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a801e30",
  null
  
)

export default component.exports