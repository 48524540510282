<template>
    <div style="background-color: #f7f8fa;">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                基础信息
            </div>
            <van-form>
                <van-count-down
                        ref="countDown"
                        hidden
                        second
                        time="30000"
                        :auto-start="false"
                        format="ss"
                        @change = "changetime"
                        @finish="finishok"
                />
                <van-field
                        v-model="phone"
                        name="手机号"
                        type="tel"
                        label="手机号"
                        @blur = "checkuserinfo"
                        placeholder="手机号"
                        :rules="[{ required: true, message: '请填写手机号' }]"
                />
                <van-field
                        v-model="sms"
                        center
                        clearable
                        label="验证码"
                        placeholder="请输入短信验证码"
                >
                    <van-button slot="button" ref="countDownBtn" size="small" :disabled="yzmabled" @touchend="getcode" @click="getcode" type="primary">
                        {{btntxt}}
                    </van-button>
                </van-field>
                <van-field
                        v-model="username"
                        name="姓名"
                        label="姓名"
                        placeholder="用户姓名"
                        :rules="[{ required: true, message: '请填写姓名' }]"
                />
                <!--
                <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="sf"
                        label="身份"
                        placeholder="点击选择身份"
                        @click="showPicker3 = true"
                />
                <van-popup v-model="showPicker3" position="bottom">
                    <van-picker
                            show-toolbar
                            :columns="sflist"
                            @confirm="onsfConfirm"
                            @cancel="showPicker3 = false"
                    />
                </van-popup>
                -->
            </van-form>
        </div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                密码确认
            </div>
            <van-form>
                <van-field
                        v-model="loginpwd"
                        name="登录密码"
                        type="password"
                        label="登录密码"
                        placeholder="请输入登录密码"
                        :rules="[{ required: true, message: '请填写登录密码' }]"
                />
                <van-field
                        v-model="repwd"
                        name="确认密码"
                        type="password"
                        label="确认密码"
                        placeholder="请再次输入密码"
                        :rules="[{ required: true, message: '请填写确认密码' }]"
                />
                <div style="margin: 16px;">
                    <van-button round block type="info" @click="onSubmit">
                        立即注册
                    </van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Picker } from 'vant';
    import { Popup } from 'vant';
    import { CountDown } from 'vant';
    import { Notify } from 'vant';

    Vue.use(Notify);
    Vue.use(CountDown);
    import Utils from '../util/utils'
    Vue.use(Popup);
    Vue.use(Picker);
    Vue.use(Field);
    Vue.use(Form);
    var that
    export default {
        name: "Regedit",
        data() {
            return {
                phone: '',
                sms: '',
                username: '',
                userimg: '',
                usernick: '',
                childnick: '',
                jsname: '',
                value: '',
                jsdes: '',
                sf: '',
                sfid: '',
                parentname: '',
                sflist: [],
                sfidlist: [],
                childname: '',
                typeid: '',
                showPicker3: false,
                yzmabled: false,
                minDate: new Date(2000, 9, 1),
                maxDate: new Date(2020, 10, 1),
                currentDate: new Date(),
                typevalue: '',
                smscode: '',
                teacher_type_list: [],
                cbirthday: '',
                teacher_type_idlist: [],
                showPicker: false,
                repwd: '',
                loginpwd: '',
                btntxt: '发送验证码'
            };
        },
        mounted: function () {
            that = this
            document.title = '用户注册'
            that.getsflist()
        },
        methods: {
            checkuserinfo () {
                var qstr = "phone = '" + that.phone + "'"
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryui',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            that.alreg = true
                        } else {
                            that.alreg = false
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            onsfConfirm (value,index) {
                window.console.log(value)
                that.sf = that.sflist[index]
                that.sfid = that.sfidlist[index]
                that.showPicker3 = false
            },
            getsflist () {
                var qstr = "id > 0 and id != '99' and id != '1' and id != '2'"
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/querypi',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.sflist.push(tmpdata[i].postname)
                                that.sfidlist.push(tmpdata[i].id)
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            changetime (val) {
                this.btntxt = '等待 ' + val.seconds + ' 秒'
            },
            finishok () {
                Notify({ type: 'warning', message: '倒计时结束' });
                this.yzmabled = false
                this.btntxt = '发送验证码'
            },
            getcode () {
                var reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
                if (this.phone !== '' && reg.test(this.phone)) {
                    window.console.log(this)
                    that.$refs.countDown.start()
                    this.yzmabled = true
                    var datatmp = {
                        vphone: this.phone,
                        issend: parseInt(1)
                    }
                    that.$http({
                        url: 'https://api.sqnetsoft.cn/account/getsms',
                        method: 'post',
                        data: datatmp,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.success) {
                                // 把相关数据缓存到本地
                                window.console.log(res)
                                that.smscode = res.data.data
                                that.sms = res.data.data
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    Notify({ type: 'warning', message: '手机号输入不正确' });
                }
            },
            login(phone,pwd) {
                var qstr = {
                    whereinfo: " phone='" + phone + "' and loginpwd='" + pwd + "'",
                    limit: '',
                    sortinfo: ''
                }

                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/mlogin',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res.data)
                        if (res.data.length >= 1) {
                            var tmpdata = res.data[0]
                            localStorage.setItem('userid', tmpdata.userid)
                            localStorage.setItem('loginid', tmpdata.userid)
                            localStorage.setItem('loginname', tmpdata.realname)
                            localStorage.setItem('userphone', phone)
                            localStorage.setItem('openid', tmpdata.openid)
                            localStorage.setItem('mpermission', tmpdata.mpermission)
                            that.$router.push({path: 'MainPage'})
                        } else {
                            Notify({ type: 'warning', message: '账号错误！' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            onSubmit(values) {
                window.console.log('submit', values);
                if (this.alreg) {
                    Notify({ type: 'warning', message: '您已经注册过了，无需重复注册' });
                } else {
                    if (this.phone !== '' && this.sms !== '' && this.sms === this.smscode && this.repwd === this.loginpwd) {
                        let addtime = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                        var adddata1
                        adddata1 = {
                            userid: '',
                            realname: this.username,
                            postid: '9',
                            nickname: this.username,
                            imgurl: '',
                            phone: this.phone,
                            state: '1',
                            loginpwd: this.repwd,
                            adddate: addtime,
                            utype: '1',
                            openid: '',
                        }

                        that.$http({
                            url: 'https://bcapi.sqnetsoft.cn/addui',
                            method: 'post',
                            data: adddata1,
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                            .then(function (res) {
                                if (res.data.result === 'success') {
                                    localStorage.setItem('userid',res.data.data)
                                    localStorage.setItem('username',that.username)
                                    that.login(that.phone,that.repwd)
                                }
                            })
                            .catch(function (error) {
                                window.console.log(error)
                            })
                    } else {
                        Notify({ type: 'warning', message: '信息不完整，请检查' });
                    }
                }


            },
            formatSecond(date) {
                return `${date.getSeconds()}`;
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                return `${date.getFullYear()}-${monthstr}-${date.getDate()}`;
            },
            dt_select (val) {
                this.showPicker = false;
                this.cbirthday = this.formatDate(val)
            },
            onConfirm(value,index) {
                this.typevalue = value
                this.typeid = this.teacher_type_idlist[index]
                this.showPicker = false;
            }
        }
    }
</script>

<style scoped>
    body {
        min-width: 1100px;
        margin: 0;
        overflow-x: auto;
        color: #323233;
        font-size: 16px;
        font-family: PingFang SC, 'Helvetica Neue', Arial, sans-serif;
        background-color: #f7f8fa;
        -webkit-font-smoothing: antialiased;
    }
    .van-doc-demo-block:first-of-type .van-doc-demo-block__title {
        padding-top: 20px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 32px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
