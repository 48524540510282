<template>
    <div style="background-color: #f7f8fa;">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                上传信息
            </div>
            <van-form>
                <van-field
                        v-model="qjdes"
                        rows="3"
                        autosize
                        label="评价"
                        type="textarea"
                        show-word-limit
                        placeholder="请填写本课学员的表现情况"
                />
                <van-field name="rate" label="评分">
                    <template #input>
                        <van-rate v-model="rate" />
                    </template>
                </van-field>
                <van-field name="uploader" label="上传">
                    <template #input>
                        <van-uploader v-model="fileList" multiple  :after-read="onRead"/>
                    </template>
                </van-field>
                <div style="margin: 16px;">
                    <van-button round block type="info" @click="saveUpload"  native-type="submit">
                        提交评价
                    </van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Rate } from 'vant';
    import { Uploader } from 'vant';
    import axios from 'axios'
    Vue.prototype.$http = axios
    Vue.use(Uploader);
    Vue.use(Rate);
    var that
    export default {
        name: "MYUpLoad",
        data() {
            return {
                rate: 3,
                postData: [],
                fileIds: [],
                fileList: []
            }
        },
        mounted: function () {
            that = this
            window.console.log(that)
        },
        methods: {
            saveUpload () {},
            delImg (index) {
                // 删除指定下标的图片对象
                if (isNaN(index) || index >= this.postData.length) {
                    return false
                }
                let tmp = []
                for (let i = 0, len = this.postData.length; i < len; i++) {
                    if (this.postData[i] !== this.postData[index]) {
                        tmp.push(this.postData[i])
                    }
                }
                this.postData = tmp
                this.fileIds.splice(index,1)
            },
            onRead (file) {
                this.postData.push(file)  // postData是一个数组
                this.fileList.push(file)
                window.console.log(file.file)
                window.console.log(file.file.name)
                let formdata1 = new FormData();
                // 通过append向form对象添加数据,可以通过append继续添加数据
                //或formdata1.append('file',file);
                formdata1.append('file',file.file,file.file.name)
                formdata1.append('bucketname','sqpro')
                formdata1.append('bucketpath','wk/others')
                let config = {
                    headers: { //添加请求头
                        'Content-Type': 'multipart/form-data'
                    }
                }
                window.console.log(config)
                this.$http.post('http://api.sqnetsoft.cn/ossupload/uploadoss', formdata1,config).then(res => {
                    this.fileIds.push(res) //这里上传到指定的图片服务器，成功后会返回图片的url
                }).catch(err => {
                    alert(err)
                })
            }
        }
    }
</script>

<style scoped>
    body {
        min-width: 1100px;
        margin: 0;
        overflow-x: auto;
        color: #323233;
        font-size: 16px;
        font-family: PingFang SC, 'Helvetica Neue', Arial, sans-serif;
        background-color: #f7f8fa;
        -webkit-font-smoothing: antialiased;
    }
    .van-doc-demo-block:first-of-type .van-doc-demo-block__title {
        padding-top: 20px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 32px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
