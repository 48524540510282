<template>
    <div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                <span style="flex:1;">班组清单</span>
                <van-icon name="add-o" style="float:right;" @click="addclass" size="18" ></van-icon>
            </div>
        </div>
        <div v-for="(item,index) in bzlist" :key="index" >
            <van-cell-group>
                <van-cell icon=" iconfont iconliebiao" is-link @click="showbz(index)">
                    <div style="width:100%; display: flex;flex-flow: row;"><span style="display: flex; justify-content: flex-start;flex: 1;">班组： {{item.classname}} <span v-if="item.classdes !== '' && item.classdes !== null">({{item.classdes}})</span></span></div>
                </van-cell>
            </van-cell-group>
        </div>
        <van-dialog v-model="showaddbz" title="添加班组" @confirm="addbz" show-cancel-button>
            <div class="van-doc-demo-block">
                <div class="van-doc-demo-block__title">
                    <div>班组信息</div>
                </div>
            </div>
            <div>
                <van-field v-model="bzname" label="班组名称" placeholder="请输入班组名称" />
                <van-field
                        v-model="bzdes"
                        rows="3"
                        autosize
                        label="班组描述"
                        type="textarea"
                        placeholder="请输入班组描述"
                />
            </div>
        </van-dialog>
    </div>
</template>


<script>
    import Vue from 'vue';
    import { Card } from 'vant';
    import { Icon } from 'vant';
    import { Pagination } from 'vant';
    import { Toast } from 'vant'
    import { RadioGroup, Radio } from 'vant';
    import { Picker,Popup } from 'vant';
    import { DatetimePicker } from 'vant';
    import { Search } from 'vant';

    Vue.use(Search);
    Vue.use(Pagination);
    Vue.use(Icon);
    Vue.use(Card);
    Vue.use(Toast)
    Vue.use(Popup,Picker);
    Vue.use(DatetimePicker);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    var that
    export default {
        name: "BZMana",
        data () {
            return {
                active: 0,
                bzlist: [],
                xylist: [],
                bzdes: '',
                bzname: '',
                showaddbz: false,
                s_xyname: ''
            }
        },
        mounted() {
            that = this
            that.getbz()
        },
        methods: {
            showbz(index) {
                window.console.log(that.bzlist)
                window.console.log(that.bzlist[index])
                localStorage.setItem('bzid',that.bzlist[index].id)
                localStorage.setItem('bzname',that.bzlist[index].classname)
                that.$router.push({path: '/BZFP'})
            },
            addbz () {
                if (that.bzname !== '' && that.bzname !== null && that.bzname !== undefined) {
                    var adata = {
                        id: '',
                        jlid: localStorage.getItem('loginid').toString(),
                        classname: that.bzname.toString(),
                        classdes: that.bzdes
                    }
                    that.$http({
                        url: 'https://bcapi.sqnetsoft.cn/addclass',
                        method: 'post',
                        data:adata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            window.console.log(res)
                            if (res.data.result === 'success') {
                                that.getbz()
                                that.showaddbz = false
                            }
                        })
                        .catch(function (error) {
                            window.console.log('error' + error)
                        })
                }
            },
            addclass () {
                that.showaddbz = true
            },
            getbz () {
                var qdata = {
                    whereinfo: " jlid = '" + localStorage.getItem('loginid') + "'",
                    limit: '',
                    sortinfo: 'order by id desc'
                }
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryclass',
                    method: 'post',
                    data:qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.bzlist = res.data
                        window.console.log(that.bzlist)
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            queryxy (value) {
                var qdata = {
                    whereinfo: " xyname like '%" + value + "%'",
                    limit: '',
                    sortinfo: 'order by id desc'
                }
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/querymember',
                    method: 'post',
                    data:qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.xylist = res.data
                        window.console.log(that.xylist)
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            }
        }

    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
