<template>
    <div class="bgbody" :style="'background-image: url(' + curbg +');height:'+ curheight +'px'">
        <img style="height: 160px;width:160px; bottom: 60px;left:30px; position: absolute; object-fit: cover;" :src="codeurl" />
    </div>
</template>

<script>
    var that
    export default {
        name: "TaskCode",
        data () {
            return {
                curbg: '',
                curheight: document.documentElement.clientHeight,
                codeurl: '',
                sybg: 'https://sqpro.oss-cn-beijing.aliyuncs.com/bc/resources/psbg.jpg',
                mzbg: 'https://sqpro.oss-cn-beijing.aliyuncs.com/bc/resources/mzbg.jpg'
            }
        },
        mounted() {
            that = this
            const URL = window.location.href.replace('#/','')
            var params = parse_url(URL)
            var index = params['index']
            window.console.log(index)
            if (index === '0') {
                window.console.log('index',index)
                that.curbg = that.sybg
            } else if (index === '1') {
                that.curbg = that.mzbg
            }
            that.codeurl = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + localStorage.getItem('fxticket')
        },
        methods: {

        }
    }
    function parse_url(url){ //定义函数
        var pattern = /(\w+)=(\w+)/ig
        var parames = {}
        url.replace(pattern, function(a, b, c){parames[b] = c})
        return parames
    }
</script>

<style scoped>
    .bgbody {
        -webkit-background-size: 100% 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -10;
        zoom: 1;
    }
</style>
