<template>
    <div>
        <div class="allpanel" @click="seeallcourse">
            <div class="all-leftpart">全部课程</div>
            <div class="all_rightpart">></div>
        </div>
        <div>
            <div v-for="(item, index) in coursetypes" :key="index">
                <div class="subpanel">
                    <div class="all-leftpart">{{item.name}}</div>
                    <div class="all_rightpart"></div>
                </div>
                <div class="contextcontainer">
                    <div v-for="(item1, index) in item.Children" :key="index">
                        <div v-if="index % 2 == 1">
                            <div :style="'width:' + colwidth + 'px;'">
                                <div class="txtcontainer">
                                    <div v-if="item1.imgurl.Valid && item1.imgurl.String !== ''">
                                        <img class="imginfo" v-lazy="item1.imgurl.String" />
                                    </div>
                                    <div class="txtinfo">{{item1.name}}</div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div :style="'width:' + colwidth + 'px'">
                                <div class="txtcontainer">
                                    <div v-if="item1.imgurl.Valid && item1.imgurl.String !== ''">
                                        <img class="imginfo" v-lazy="item1.imgurl.String" />
                                    </div>
                                    <div class="txtinfo">{{item1.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import '../../static/css/my.css'
    import axios from 'axios'
    import { Lazyload } from 'vant'
    Vue.use(Lazyload)
    Vue.prototype.$http = axios
    var that
    export default {
        name: 'Type',
        data () {
            return {
                coursetypes: [],
                colwidth: document.body.clientWidth / 2
            }
        },
        mounted: function () {
            that = this
            window.onresize = () => {
                return (() => {
                    that.colwidth = document.body.clientWidth / 2
                })()
            }
            that.$options.methods.getcoursetypes()
        },
        methods: {
            getcoursetypes: function () {
                that.$http({
                    url: 'https://dfsd.sqnetsoft.cn/course/gtct',
                    method: 'post',
                    data: {
                        parentid: parseInt(-1),
                        cusid: parseInt(1)
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            that.coursetypes = JSON.parse(res.data.data)[0].Children
                            window.console.log(that.coursetypes[0])
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            seeallcourse: function () {
                this.$router.push('/CourseList')
            }
        }
    }
</script>
