<template>
    <div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                业绩查询
            </div>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="xq"
                    label="校区"
                    placeholder="点击选择校区"
                    @click="showPicker3 = true"
            />
            <van-popup v-model="showPicker3" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="xqlist"
                        @confirm="onxqConfirm"
                        @cancel="showPicker3 = false"
                />
            </van-popup>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="td"
                    label="团队"
                    placeholder="点击选择团队"
                    @click="showPicker4 = true"
            />
            <van-popup v-model="showPicker4" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="tdlist"
                        @confirm="ontdConfirm"
                        @cancel="showPicker4 = false"
                />
            </van-popup>
            <van-field
                    readonly
                    clickable
                    name="datetimePicker"
                    :value="sjstartvalue"
                    label="开始时间"
                    placeholder="点击选择时间"
                    @click="showPicker1 = true"
            />
            <van-popup v-model="showPicker1" position="bottom">
                <van-datetime-picker
                        type="date"
                        v-model="currentDate"
                        @confirm="onSJConfirm"
                        @cancel="showPicker1 = false"
                />
            </van-popup>
            <van-field
                    readonly
                    clickable
                    name="datetimePicker"
                    :value="sjendvalue"
                    label="结束时间"
                    placeholder="点击结束时间"
                    @click="showPicker2 = true"
            />
            <van-popup v-model="showPicker2" position="bottom">
                <van-datetime-picker
                        type="date"
                        v-model="currentDate"
                        @confirm="onSJEndConfirm"
                        @cancel="showPicker2 = false"
                />
            </van-popup>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit" @click="getxq">
                    查询业绩
                </van-button>
            </div>
        </div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                业绩统计
            </div>
            <div style="padding: 10px;" id="mymainChart"  class="chartcard">
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Card } from 'vant';
    import { Icon } from 'vant';
    import { Pagination } from 'vant';
    import { Toast } from 'vant'
    import { RadioGroup, Radio } from 'vant';
    import { Picker,Popup } from 'vant';
    import { DatetimePicker } from 'vant';
    Vue.use(Pagination);
    Vue.use(Icon);
    Vue.use(Card);
    Vue.use(Toast)
    Vue.use(Popup,Picker);
    Vue.use(DatetimePicker);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    var that
    var myChart
    export default {
        name: "TJXD",
        data () {
            return {
                showPicker1: false,
                showPicker2: false,
                showPicker3: false,
                showPicker4: false,
                tdlist: [],
                td: '',
                tdid: '',
                tdidlist: [],
                xqlist: [],
                xqidlist: [],
                xq: '',
                xqid: '',
                sjstartvalue: '',
                loading: false,
                sjendvalue: '',
                currentDate: ''
            }
        },
        mounted() {
            that = this
            window.console.log(that)
            that.currentDate = new Date()
            document.title = '校区统计'
            that.getxqlist()
            that.gettdlist()
        },
        methods: {
            gettdlist () {
                var qstr = 'id > 0'
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/querytd',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.tdlist.push(tmpdata[i].tdname)
                                that.tdidlist.push(tmpdata[i].id)
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getxqlist () {
                var qstr = 'id > 0'
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryxq',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.xqlist.push(tmpdata[i].xqname)
                                that.xqidlist.push(tmpdata[i].id)
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                var daystr = ''
                if(parseInt(date.getDate() + 1) <= 10) {
                    daystr = '0' + parseInt(date.getDate())
                } else {
                    daystr = date.getDate()
                }
                return `${date.getFullYear()}-${monthstr}-${daystr}`;
            },
            onSJConfirm (date) {
                this.sjstartvalue = this.formatDate(date);
                this.showPicker1 = false;
            },
            onxqConfirm (value,index) {
                window.console.log(value)
                that.xq = that.xqlist[index]
                window.console.log(that.xq)
                that.xqid = that.xqidlist[index]
                that.showPicker3 = false
            },
            ontdConfirm (value,index) {
                that.td = that.tdlist[index]
                window.console.log(that.td)
                that.tdid = that.tdidlist[index]
                that.showPicker4 = false
            },
            onSJEndConfirm (date) {
                this.sjendvalue = this.formatDate(date);
                this.showPicker2 = false;
            },
            getxq() {
                var qstr1 = ' jlid > 0'
                if (that.sjstartvalue !== null && that.sjendvalue !== '') {
                    qstr1 = qstr1 + " and orderdate >= '" + that.sjstartvalue + "' and orderdate<='" + that.sjendvalue + "'"
                }
                if (that.xqid !== null && that.xqid !== '') {
                    qstr1 = qstr1 + " and xqid = '" + that.xqid + "'"
                }
                if (that.tdid !== null && that.tdid !== '') {
                    qstr1 = qstr1 + " and tdid = '" + that.tdid + "'"
                }
                var qstr = {
                    whereinfo: qstr1
                }
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryxqorder',
                    method: 'post',
                    data:qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        var tmpdata = res.data
                        var xllist = []
                        for (var i = 0;i < tmpdata.length; i++) {
                            if (tmpdata[i].xqname !== null) {
                                var datatmp = {
                                    name: tmpdata[i].xqname,
                                    value: tmpdata[i].cprice
                                }
                                xllist.push(datatmp)
                            }
                        }
                        that.drawLine(xllist)
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            drawLine (xllist) {
                // 基于准备好的dom，初始化echarts实例
                myChart = this.$echarts.init(document.getElementById('mymainChart'))
                // 绘制图表
                myChart.setOption({
                    title: {
                        text: '校区销量',
                        subtext: '近期',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'left',
                    },
                    series: [
                        {
                            name: '校区销量',
                            type: 'pie',
                            radius: '50%',
                            data:xllist,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                })
                window.onresize = myChart.resize
            }
        }
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
    .chartcard {
        height: 350px;
        padding: 2px;
    }
</style>
