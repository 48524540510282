<template>
    <div>
         <van-swipe :autoplay="3000">
             <van-swipe-item v-for="(image, index) in branddata" :key="index" style="display: flex;align-items: center;justify-content: center;">
                 <img style="width: 100%; height: auto; display: block;" v-lazy="image.adurl.String" />
             </van-swipe-item>
         </van-swipe>
        <div>
            <div class="van-doc-demo-block">
                <div class="van-doc-demo-block__title">
                    <span style="flex:1;">您好，{{username}} (功能清单)</span>
                    <van-icon name="exchange" style="float:right;" @click="returnlogin" size="18" ></van-icon>
                </div>
            </div>
            <div>
                <!-- 如果分两列，对应代码
                <div class="van-doc-demo-block">
                    <div class="van-doc-demo-block__title">
                        {{item.Name}}
                    </div>
                </div>
                -->
                <van-grid>
                    <van-grid-item @click="showgn(item.ModelUrl.String)" v-for="(item,index) in gnlist" :key="index">
                        <i :class="item.Icon.String" style="font-size:28px"></i>
                        <div class="ordertxt">{{item.Name}}</div>
                    </van-grid-item>
                </van-grid>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import '../../static/css/my.css'
    import '../../static/font/iconfont.css'
    import axios from 'axios'
    import { Lazyload } from 'vant'
    import { Swipe, SwipeItem } from 'vant'
    import { Grid, GridItem } from 'vant';
    import wx from 'weixin-js-sdk'
    import { NavBar } from 'vant';

    Vue.use(NavBar);
    Vue.use(Grid);
    Vue.use(GridItem);
    Vue.use(Swipe).use(SwipeItem)
    Vue.use(Lazyload)
    Vue.prototype.$http = axios
    var that
    var wxinfo = wx
    import { Notify } from 'vant';

    Vue.use(Notify);
    export default {
        name: 'MainPage',
        data () {
            return {
                active: 0,
                branddata: [],
                kjrkdata: [],
                recommtypes: [],
                goodsrec: [],
                username: '',
                allscreenWidth: (document.body.clientWidth / 5) - 5,
                screenWidth: (document.body.clientWidth / 2),
                gnlist: '',
                khid: 1,
            }
        },
        mounted: function () {
            that = this
            // 判断 OpenID 是否存在
            that.username = localStorage.getItem('loginname')
            // Notify({ type: 'success', message:'您好，' + localStorage.getItem('loginname')})
            that.checekuser()
        },
        methods: {
            returnlogin () {
                that.$router.push({path: '/Login'})
            },
            showgn (url) {
                window.console.log(url)
                that.$router.push({path: url})
            },
            checekuser () {
                if (localStorage.getItem('loginname') !== null && localStorage.getItem('loginname') !== '' && localStorage.getItem('loginid') !== '' && localStorage.getItem('loginid') !== null) {
                    window.console.log('正常获取用户信息')
                    /// 如果分成两列 直接赋值就好
                    /// this.gnlist = JSON.parse(localStorage.getItem('mpermission'))[0].Children[0].Children
                    /// 如果单列显示，需要重新排序
                    this.gnlist = []
                    window.console.log(localStorage.getItem('mpermission'))
                    if (localStorage.getItem('mpermission') !== null && localStorage.getItem('mpermission') !== 'null') {
                        var tmpdata = JSON.parse(localStorage.getItem('mpermission'))[0].Children[0].Children
                        for (var i = 0;i < tmpdata.length; i++) {
                            for (var j = 0; j < tmpdata[i].Children.length; j++) {
                                this.gnlist.push(tmpdata[i].Children[j])
                            }
                        }
                    }
                } else {
                    that.$router.push({path: 'Login'})
                }
            },
            courseadd () {
                that.$router.push({path: 'CourseAdd'})
            },
            courseqr () {
                that.$router.push({path: 'CourseQR'})
            },
            querycourse () {
                that.$router.push({path: 'QueryCourse'})
            },
            tjcourse () {
                that.$router.push({path: 'TJCourse'})
            },
            checkuser: function () {
                // 依照openid来查看有没有对应用户
                var qstr1 = "openid = '" + localStorage.getItem('openid') + "'"
                that.$http({
                    url: 'https://api.sqnetsoft.cn/member/gbmu',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr1, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data.toString() !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                if (tmpdata[0].id === 0) {
                                    window.console.log('======= userid 获取失败  ========')
                                    localStorage.setItem('UserId',0)
                                } else {
                                    window.console.log('======= 已获取userid  ========')
                                    localStorage.setItem("UserId",tmpdata[0].id.Int64)
                                    localStorage.setItem("UserName",tmpdata[0].parentname.String)
                                    Notify({ type: 'success', message: '您好,' + tmpdata[0].parentname.String });
                                }
                            } else {
                                window.console.log('======= userid 获取失败  ========')
                                localStorage.setItem('UserId',0)
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })

            },
            configwx: function () {
                // 获取微信签名
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/getwxjsparams',
                    method: 'post',
                    data: {
                        shareurl: 'http://ecomm.sqnetsoft.cn/pxplatuser/',
                        accesstoken: getCookie('accesstoken')
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.status === 200) {
                            var tmpdata = res.data
                            window.console.log(tmpdata)
                            var sha1sign = tmpdata.sh1signature
                            // var timestamp = Date.parse(new Date()).toString()
                            // timestamp = timestamp.substr(0,10)
                            var noncestr1 = randomWord(false, 30)
                            window.console.log(noncestr1)
                            var timestamp = tmpdata.timestamp
                            var noncestr = tmpdata.nonce
                            window.console.log(wxinfo)
                            wxinfo.config({
                                beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
                                appId: 'wxb6b6a21ef8474770', //填，企业微信的corpID
                                timestamp: timestamp, // 必填，生成签名的时间戳
                                nonceStr: noncestr, // 必填，生成签名的随机串 必填，生成签名的随机串
                                signature: sha1sign, // 必填，签名
                                jsApiList: [
                                    "onMenuShareTimeline",//分享朋友圈接口
                                    "onMenuShareAppMessage"//分享给朋友接口
                                ]
                            })
                            window.console.log(res)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            checkuserid: function () {
                window.console.log('======= 正在检测userid  ========')
                var tmpdata = JSON.parse(localStorage.getItem('userinfo'))
                that.userimg = tmpdata.headimgurl
                that.usernick = tmpdata.nickname
                if (localStorage.getItem('UserId') === null || localStorage.getItem('UserId') === '0') {
                    window.console.log('======= userid 获取失败  ========')
                    that.$options.methods.checkuser()
                } else {
                    window.console.log('======= userid 已获取  ========')
                    Notify({ type: 'success', message: '您好,' + localStorage.getItem('UserName') });
                }
            },
            reguserbaseinfo: function () {
                window.console.log('======= 保存用户信息  ========')
                var tmpdata = JSON.parse(localStorage.getItem('userinfo'))
                that.userimg = tmpdata.headimgurl
                that.usernick = tmpdata.nickname
                var adddata = {
                    openid: localStorage.getItem('openid'),
                    userimg: {String: that.userimg,Valid:true},
                    usernick: {String: that.usernick,Valid:true},
                    phone: {String: '',Valid:true},
                    name: {String: that.usernick,Valid:true},
                    typeid: parseInt(2),
                    cusid: parseInt(1)
                }
                that.$http({
                    url: 'https://api.sqnetsoft.cn/member/abuw',
                    method: 'post',
                    data: adddata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            window.console.log('已保存信息')
                            window.console.log(res)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getuserinfo: function () {
                window.console.log('======= 正在获取userinfo  ========')
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/getuserinfo',
                    method: 'post',
                    data: {
                        accesstoken: getCookie('accesstoken'),
                        openid: localStorage.getItem('openid')
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var tmpdata = JSON.parse(res.data.data)
                            if (tmpdata) {
                                localStorage.setItem('userinfo',res.data.data)
                                that.$options.methods.checkuserid()
                                that.$options.methods.reguserbaseinfo()
                            } else {
                                localStorage.setItem('userinfo','')
                            }
                            window.console.log(res)
                            // 把相关数据缓存到本地
                        } else {
                            localStorage.setItem('userinfo','')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            checkuserinfo: function () {
                window.console.log('======= 开始 checkuserinfo  ========')
                if (localStorage.getItem('userinfo') === null || localStorage.getItem('userinfo') === '') {
                    window.console.log('======= userinfo 未获取，重新获取  ========')
                    that.$options.methods.getuserinfo()
                } else {
                    window.console.log('======= userinfo 已获取，检测userid  ========')
                    that.$options.methods.checkuserid()

                }
            },
            checkopenid: function () {
                window.console.log('======= 开始 checkopenid  ========')
                const URL = window.location.href.replace('#/','')
                var params = parse_url(URL)
                if (localStorage.getItem('openid') === null || localStorage.getItem('openid') === '') {
                    window.console.log('======= 未检测到openid，准备获取中 ========')
                    if (params['code'] !== '' && params['code'] !== undefined) {
                        window.console.log('======= 获取openid ,第一步已检测到code，准备获取中 ========')
                        // 如果有code参数，才会执行获取，如果没有则不需要处理
                        that.$options.methods.getopenid(params)
                    } else {
                        window.console.log('======= 获取openid ,第一步未检测到code，准备获取中 ========')
                        if (getCookie('refreshtoken') === '' || getCookie('refreshtoken') === null) {
                            window.console.log('======= 获取openid ,第二步未检测到refreshtoken，获取code中 ========')
                            // 本地调试 暂时注销
                            window.location.href = 'https://api.sqnetsoft.cn/wx/getopcode?url=pxplatuser'

                        } else {
                            window.console.log('======= 获取openid ,第二步检测到refreshtoken，重新checkopenid ========')
                            that.$options.methods.reloadaccesstoken(getCookie('refreshtoken'))
                        }
                    }
                } else {
                    window.console.log('======= 存在openid ========')
                    that.$options.methods.checkuserinfo()
                }
            },
            getaccesstoken: function () {
                window.console.log('======= 获取 accesstoken  ========')
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/getaccesstoken',
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            window.console.log('======= 已成功获取 accesstoken ========')
                            setCookie('accesstoken',res.data.data,7200)
                            that.$options.methods.checkopenid()
                            that.$options.methods.configwx()
                            // 把相关数据缓存到本地
                        } else {
                            Notify({ type: 'error', message: 'accesstoken已失效,请联系管理人员' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            reloadaccesstoken: function (refreshtoken) {
                window.console.log('======= 获取 reloadaccesstoken  ========')
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/reloadaccesstoken',
                    method: 'post',
                    data: {
                        refreshtoken: refreshtoken
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var tmpdata = JSON.parse(res.data.data)
                            if (tmpdata.access_token) {
                                setCookie('authaccesstoken',tmpdata.access_token,7200)
                                setCookie('refreshtoken',tmpdata.refresh_token,2592000)
                                localStorage.setItem('openid',tmpdata.openid)
                                that.$options.methods.checkopenid()
                                that.$options.methods.configwx()
                            } else {
                                Notify({ type: 'error', message: 'reloadaccesstoken失败,请联系管理人员' });
                            }
                            // 把相关数据缓存到本地
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getopenid: function (params) {
                window.console.log(params['code'])
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/getgzhopenidex',
                    method: 'post',
                    data: {
                        code: params['code']
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var tmpdata = JSON.parse(res.data.data)
                            if (tmpdata.access_token) {
                                setCookie('authaccesstoken',tmpdata.access_token,7200)
                                setCookie('refreshtoken',tmpdata.refresh_token,2592000)
                                localStorage.setItem('openid',tmpdata.openid)
                                that.$options.methods.checkuserinfo()
                            }
                            // 把相关数据缓存到本地
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            seeall: function () {
                this.$router.push('/Type')
            },
            showxd: function() {
                that.$router.push({path: 'ShowXD'})
            },
            xdsh: function () {
                that.$router.push({path: 'XDSH'})
            },
            queryxd: function () {
                that.$router.push({path: 'QueryXD'})
            },
            tjxd: function () {
                that.$router.push({path: 'TJXD'})
            }
        }
    }
    function parse_url(url){ //定义函数
        var pattern = /(\w+)=(\w+)/ig
        var parames = {}
        url.replace(pattern, function(a, b, c){parames[b] = c})
        return parames
    }
    function setCookie (name, value, seconds) {
        var d = new Date()
        d.setTime(d.getTime() + 1000 * seconds)
        window.document.cookie = name + '=' + value + ';path=/;expires=' + d.toGMTString()
    }
    function getCookie (name) {
        var v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
        return v ? v[2] : null
    }
    function randomWord (randomFlag, min, max) {
        var str = ''
        var range = min
        var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
        // 随机产生
        if (randomFlag) {
            range = Math.round(Math.random() * (max - min)) + min
        }
        for (var i = 0; i < range; i++) {
            var pos = Math.round(Math.random() * (arr.length - 1))
            str += arr[pos]
        }
        return str
    }
</script>
<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
