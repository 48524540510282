<template>
    <div>
        <van-search v-model="s_xyname" @search="queryxy" placeholder="请输入搜索学员姓名" />
        <van-radio-group  v-model="xfinfo" style="margin: 20px; font-size: 14px;">
            <van-cell-group>
                <van-cell v-for="(item,index) in xflist" @click="queryxfinfo(index)" :key="item.id" clickable>
                    <template #right-icon>
                        <van-radio :name="item" />
                    </template>
                    <template #title>
                        <div v-if="item.kctypename.includes('VIP') || item.kctypename.includes('封闭')">
                            <div style="width:100%; display: flex;flex-flow: row;"><span style="display: flex; justify-content: flex-start;flex: 1;">课次类型： {{item.kctypename}} , 到期时间：{{item.enddate}}</span></div>
                        </div>
                        <div v-else-if="item.kctypename.includes('特训')">
                            <div style="width:100%; display: flex;flex-flow: row;"><span style="display: flex; justify-content: flex-start;flex: 1;">课次类型： {{item.kctypename}} , 剩余次数：{{item.sycount}}</span></div>
                        </div>
                        <div v-else>
                            <div style="width:100%; display: flex;flex-flow: row;"><span style="display: flex; justify-content: flex-start;flex: 1;">课次类型： {{item.kctypename}} , 剩余次数：{{item.sycount}}</span></div>
                        </div>
                    </template>
                </van-cell>
            </van-cell-group>
        </van-radio-group>
        <van-tabs v-model="active">
            <van-tab title="课次管理">
                <div class="van-doc-demo-block__title">
                    <span style="flex:1;">增加课次</span>
                </div>
                <van-field
                        v-model="sycount"
                        name="剩余次数"
                        label="剩余次数"
                        type="digit"
                        placeholder="剩余次数"
                        :rules="[{ required: true, message: '请填写课次' }]"
                />
                <van-field
                        v-model="dcprice"
                        name="单次费用"
                        label="单次费用"
                        type="digit"
                        placeholder="单次费用"
                        :rules="[{ required: true, message: '请填写课次' }]"
                />
                <van-field
                        v-model="zscount"
                        name="赠送次数"
                        label="赠送次数"
                        type="digit"
                        placeholder="赠送次数"
                        :rules="[{ required: true, message: '请填写课次' }]"
                />
                <div style="padding: 20px;">
                    <van-button round block :disabled="kc_disable" type="info" native-type="submit" @click="addkc">
                        更新课次
                    </van-button>
                </div>
            </van-tab>
            <van-tab title="时长管理">
                <div class="van-doc-demo-block__title">
                    <span style="flex:1;">增加时长</span>
                </div>
                <van-field
                        clickable
                        name="datetimePicker"
                        :value="dq_orderdate"
                        label="到期日期"
                        placeholder="点击选择到期时期"
                        @click="showPicker11 = true"
                />
                <van-popup v-model="showPicker11" position="bottom">
                    <van-datetime-picker
                            type="date"
                            v-model="currentDate"
                            @confirm="onDQConfirm"
                            @cancel="showPicker11 = false"
                    />
                </van-popup>
                <div style="padding: 20px;">
                    <van-button round block :disabled="ks_disable" type="info" native-type="submit" @click="ycks">
                        延长课时
                    </van-button>
                </div>
            </van-tab>
        </van-tabs>
        <van-dialog v-model="showqueryxy" title="显示搜索学员" show-cancel-button>
            <van-radio-group  v-model="xyinfo" style="margin: 20px; font-size: 14px;">
                <van-cell-group>
                    <van-cell v-for="(item,index) in xylist" @click="queryxyinfo(index)" :key="item.id" clickable>
                        <template #right-icon>
                            <van-radio :name="item" />
                        </template>
                        <template #title>
                            <div style="width:100%; display: flex;flex-flow: row;"><span style="display: flex; justify-content: flex-start;flex: 1;">学员名： {{item.xyname}} , 电话：{{item.phone}}</span></div>
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-radio-group>
        </van-dialog>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Card } from 'vant';
    import { Icon } from 'vant';
    import { Pagination } from 'vant';
    import { Toast } from 'vant'
    import { RadioGroup, Radio } from 'vant';
    import { Picker,Popup } from 'vant';
    import { DatetimePicker } from 'vant';
    import { Search } from 'vant';
    Vue.use(Search);
    Vue.use(Pagination);
    Vue.use(Icon);
    Vue.use(Card);
    Vue.use(Toast)
    Vue.use(Popup,Picker);
    Vue.use(DatetimePicker);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    var that
    export default {
        name: "YSXK",
        data () {
            return {
                showqueryxy: false,
                s_xyname: '',
                xylist: [],
                xfinfo: '',
                selxfinfo: '',
                xyinfo: '',
                active: 0,
                dcprice: '',
                dq_orderdate: '',
                xflist: [],
                kc_disable: false,
                ks_disable: false,
                currentDate: '',
                zscount: '',
                sycount: '',
                memberinfo: '',
                showPicker11: false
            }
        },
        mounted() {
            that = this
            that.currentDate = new Date()
        },
        methods: {
            addkc () {
                if (that.sycount !== '' && that.zscount !== '' && that.dcprice !== '') {
                    var updata = {
                        sycount: that.sycount.toString(),
                        zscount: that.zscount.toString(),
                        dcprice: that.dcprice.toString(),
                        startdate: that.selxfinfo.startdate,
                        memberid: that.selxfinfo.memberid.toString()
                    }
                    that.$http({
                        url: 'https://bcapi.sqnetsoft.cn/upmcounts',
                        method: 'post',
                        data: updata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            window.console.log(res)
                            if (res.data.result === 'success') {
                                Toast('更新完毕!');
                            }
                        })
                        .catch(function (error) {
                            window.console.log('error' + error)
                        })
                } else {
                    Toast('信息不完整!');
                }
            },
            ycks () {
                if (that.dq_orderdate !== '') {
                    var updata = {
                        startdate: that.selxfinfo.startdate,
                        memberid: that.selxfinfo.memberid.toString(),
                        enddate: that.dq_orderdate
                    }
                    that.$http({
                        url: 'https://bcapi.sqnetsoft.cn/upmcountdate',
                        method: 'post',
                        data: updata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            window.console.log(res)
                            if (res.data.result === 'success') {
                                Toast('更新完毕!');
                            }
                        })
                        .catch(function (error) {
                            window.console.log('error' + error)
                        })
                } else {
                    Toast('信息不完整!');
                }
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                var daystr = ''
                if(parseInt(date.getDate() + 1) <= 10) {
                    daystr = '0' + parseInt(date.getDate())
                } else {
                    daystr = date.getDate()
                }
                return `${date.getFullYear()}-${monthstr}-${daystr}`;
            },
            onDQConfirm (date) {
                this.dq_orderdate = this.formatDate(date);
                this.showPicker11 = false;
            },
            queryxyinfo (index) {
                that.showqueryxy = false
                window.console.log(that.xylist[index])
                var datatmp  = that.formatDate(new Date())
                var qstr = " startdate <= '" + datatmp + "' and enddate >= '" + datatmp + "' and (zscount > 0 || sycount > 0) and memberid='" + that.xylist[index].id + "'"
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryksmember',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.length > 0) {
                            that.xflist = res.data
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            queryxfinfo (index) {
                var tmpdata = that.xflist[index]
                that.selxfinfo = tmpdata
                if (tmpdata.kctypename.includes('VIP') || tmpdata.kctypename.includes('封闭')) {
                    that.active = 1
                    that.kc_disable = true
                    that.ks_disable = false
                    that.dcprice = ''
                    that.sycount = ''
                    that.zscount = ''
                    that.dq_orderdate = tmpdata.enddate
                    that.currentDate = new Date(tmpdata.enddate)
                } else if (tmpdata.kctypename.includes('特训')){
                    that.active = 0
                    that.kc_disable = false
                    that.ks_disable = true
                    that.dcprice = tmpdata.dcprice
                    that.sycount = tmpdata.sycount
                    that.zscount = tmpdata.zscount
                    that.dq_orderdate = tmpdata.enddate
                    that.currentDate = new Date(tmpdata.enddate)
                } else {
                    that.active = 0
                    that.kc_disable = false
                    that.ks_disable = true
                    that.dcprice = tmpdata.dcprice
                    that.sycount = tmpdata.sycount
                    that.zscount = tmpdata.zscount
                    that.dq_orderdate = tmpdata.enddate
                    that.currentDate = new Date(tmpdata.enddate)
                }
            },
            queryxy (value) {
                that.showqueryxy = true
                var qdata = {
                    whereinfo: " xyname like '%" + value + "%'",
                    limit: '',
                    sortinfo: 'order by id desc'
                }
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/querymember',
                    method: 'post',
                    data:qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.xylist = res.data
                        window.console.log(that.xylist)
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            }
        }
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
