<template>
    <div>
        <div style="height: 1px;background-color: #ccc;margin-bottom: 10px;"></div>
        <div style="padding:5px;border-bottom:1px solid #ccc; margin-top: 5px;margin-left: 20px; margin-right: 20px;display: flex;flex-flow: column;" v-for="(item,index) in tasklist" :key="index">
            <div style="display: flex;flex-flow: row;" @click="getsharecode(index)">
                <div><img style="border-radius: 5px;width: 120px;height: 80px;" :src="item.taskimg"></div>
                <div style="margin-left: 20px;">
                    <div style="font-weight: bold;font-size: 14px;">{{item.taskname}}</div>
                    <div style="color: #8c939d;font-size: 12px;margin-top: 10px;">{{item.taskdes}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import '../../static/css/my.css'
    import '../../static/font/iconfont.css'
    import axios from 'axios'
    import { Lazyload } from 'vant'
    import { Swipe, SwipeItem } from 'vant'
    import { Checkbox, CheckboxGroup } from 'vant';
    import { Notify } from 'vant';

    Vue.use(Notify);
    Vue.use(Checkbox);
    Vue.use(CheckboxGroup);
    Vue.use(Swipe).use(SwipeItem)
    Vue.use(Lazyload)
    Vue.prototype.$http = axios
    var that
    export default {
        name: "TaskFx",
        data () {
            return {
                nickname: '',
                cururl: '',
                tasklist: [
                    {
                        id: 1,
                        taskname: '摄影活动分享',
                        taskdes: '把美好的愿望寄托在每一天，定格您最美的身影，悠悠岁月里，让珍贵的回忆陪伴身旁。',
                        taskimg: 'https://sqpro.oss-cn-beijing.aliyuncs.com/bc/resources/pstask.jpg'
                    },
                    {
                        id: 2,
                        taskname: '美妆活动分享',
                        taskdes: '给自己一个奇迹，完美蜕变，破茧成蝶，惊喜尽在宾臣。',
                        taskimg: 'https://sqpro.oss-cn-beijing.aliyuncs.com/bc/resources/mztask.jpg'
                    }
                ]
            }
        },
        async mounted() {
            that = this
            // 本地测试
            //localStorage.setItem('openid','og6Ml5oXZz9NSTVpNwZ5chz4JMfM')
            //localStorage.setItem('nickname','晓朔')
            //localStorage.setItem('headimgurl','https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83erk8qrdRgueicK0p3McD67yicL6jQYVrUDJibQXVbibpLlpvt0de4woXBr73Vuux6yphUpGDul3kQjibVA/132')
            // 检测 OpenID
            // 去除自我检测
            /*
            if (localStorage.getItem('openid') === 'og6Ml5oXZz9NSTVpNwZ5chz4JMfM') {
                localStorage.setItem('openid','')
            }*/
            that.checkopenid()
            var at = await getaccesstoken()
            that.fxm(at)
        },
        methods: {
            fxm (at) {
                var qdata = {
                    action_name: 'QR_LIMIT_STR_SCENE',
                    action_info: {
                        scene: {
                            scene_str: localStorage.getItem('openid')
                        }
                    },
                    color: '#000000',
                    accesstoken: at
                }
                that.$http({
                    url: 'https://wx.sqnetsoft.cn/wx/makecode',
                    method: 'post',
                    data: qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            // 把相关数据缓存到本地
                            var tmpdata = JSON.parse(res.data.data)
                            localStorage.setItem('fxticket',tmpdata.ticket)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getsharecode (index) {
                window.console.log(index)
                that.$router.push({path: '/TaskCode?index=' + index})
            },
            upwxuserinfo () {
                var rdata = {
                    userphone: localStorage.getItem('userphone'),
                    nickname: localStorage.getItem('nickname'),
                    imgurl: localStorage.getItem('headimgurl'),
                    openid: localStorage.getItem('openid')
                }
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/upwxuserinfo',
                    method: 'post',
                    data: rdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            Notify({ type: 'success', message: '更新昵称完毕！' });
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getuserinfo (code) {
                var rdata = {
                    code: code
                }
                that.$http({
                    url: 'https://wx.sqnetsoft.cn/wx/getopenid',
                    method: 'post',
                    data: rdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.state === 'success') {
                            var tmpdata = res.data
                            localStorage.setItem('openid',tmpdata.openid)
                            localStorage.setItem('nickname',tmpdata.nickname)
                            localStorage.setItem('headimgurl',tmpdata.headimgurl)
                            // that.nickname = tmpdata.nickname
                            that.upwxuserinfo()
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            checkopenid: function () {
                window.console.log('======= 开始 checkopenid  ========')
                const URL = window.location.href.replace('#/','')
                var params = parse_url(URL)
                if (localStorage.getItem('openid') === null || localStorage.getItem('openid') === '') {
                    window.console.log('======= 未检测到openid，准备获取中 ========')
                    if (params['code'] !== '' && params['code'] !== undefined) {
                        window.console.log('======= 获取openid ,第一步已检测到code，准备获取中 ========')
                        // 如果有code参数，才会执行获取，如果没有则不需要处理
                        that.getuserinfo(params['code'])
                    } else {
                        window.console.log('======= 获取openid ,第一步未检测到code，准备获取中 ========')
                        that.cururl = window.location.href
                        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb6b6a21ef8474770&redirect_uri=' + encodeURIComponent(that.cururl) + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
                    }
                } else {
                    window.console.log('======= 存在openid ========')
                    that.nickname = localStorage.getItem('nickname')
                }
            }
        }
    }
    function parse_url(url){ //定义函数
        var pattern = /(\w+)=(\w+)/ig
        var parames = {}
        url.replace(pattern, function(a, b, c){parames[b] = c})
        return parames
    }
    async function getaccesstoken () {
        return new Promise(resolve => {
            that.$http({
                url: 'https://wx.sqnetsoft.cn/wx/getaccesstoken',
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {
                    window.console.log(res)
                    if (res.status === 200) {
                        window.console.log(res.data)
                        resolve(res.data)
                    } else {
                        window.console.log('返回错误')
                        resolve('')
                    }
                })
                .catch(function (error) {
                    window.console.log(error)
                    resolve('')
                })
        })
    }
</script>

<style scoped>

</style>
