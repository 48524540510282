<template>
    <div>
        <van-nav-bar
                title="我的课表"
                left-text="返回"
                right-text="日期选择"
                left-arrow
                @click-left="returnyw"
                @click-right="rqshow = true"
        />
        <van-calendar v-model="rqshow" @confirm="onConfirm" :style="{ height: '500px' }"/>
        <van-tabs v-model="active">
            <van-tab title="待上课">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="记录结束"
                            @load="onSKRecLoad"
                    >
                            <div>
                                <van-grid :border="false" :column-num="4">
                                    <van-grid-item text="上课时间" />
                                    <van-grid-item text="讲师姓名" />
                                    <van-grid-item text="班级名称" />
                                    <van-grid-item text="课程名称" />
                                </van-grid>
                                <div style="width:100%;background-color: #f2f3f5;height:1px;"></div>
                            </div>
                            <div v-for="item in sklist" :key="item"  >
                                <van-grid :border="false" :column-num="4">
                                    <van-grid-item :text="item.weektime.String" />
                                    <van-grid-item :text="item.name.String" />
                                    <van-grid-item :text="item.cname.String" />
                                    <van-grid-item :text="item.ctitle.String" />
                                </van-grid>
                            </div>
                    </van-list>
                </van-pull-refresh>
            </van-tab>
            <van-tab title="已上课"></van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Calendar } from 'vant';
    import { DatetimePicker } from 'vant';
    import { Tab, Tabs } from 'vant';
    import { List } from 'vant';
    import { PullRefresh } from 'vant';
    import { Cell, CellGroup } from 'vant';
    import { NavBar } from 'vant';
    import { Grid, GridItem } from 'vant';
    import { Notify } from 'vant';
    import axios from 'axios'
    Vue.prototype.$http = axios
    Vue.use(Notify);
    Vue.use(Grid);
    Vue.use(GridItem);

    Vue.use(NavBar);
    Vue.use(Cell);
    Vue.use(CellGroup);
    Vue.use(PullRefresh);
    Vue.use(List);
    Vue.use(Tab);
    Vue.use(Tabs);
    Vue.use(DatetimePicker);
    Vue.use(Calendar);
    var that
    export default {
        name: "MyCourse",
        data() {
            return {
                date: '',
                rqshow: false,
                sklist: [],
                loading: false,
                finished: false,
                refreshing: false,
                active: 0,
                weekid: 0,
                khid: ''
            }
        },
        mounted: function () {
            that = this
            var myDate = new Date();
            var days = myDate.getDay();
            that.weekid = days
            that.$options.methods.checkuserid()
            that.$options.methods.onSKRecLoad();
        },
        methods: {
            checkuserid: function () {
                window.console.log('======= 正在检测userid  ========')
                var tmpdata = JSON.parse(localStorage.getItem('userinfo'))
                that.userimg = tmpdata.headimgurl
                that.usernick = tmpdata.nickname
                if (localStorage.getItem('UserId') === null || localStorage.getItem('UserId') === '0') {
                    window.console.log('======= userid 获取失败  ========')
                    that.$options.methods.checkuser()
                } else {
                    window.console.log('======= userid 已获取  ========')
                    Notify({ type: 'success', message: '您好,' + localStorage.getItem('UserName') });
                }
            },
            checkuser: function () {
                // 依照openid来查看有没有对应用户
                var qstr1 = "openid = '" + localStorage.getItem('openid') + "'"
                that.$http({
                    url: 'https://api.sqnetsoft.cn/member/gbmu',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr1, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data.toString() !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                if (tmpdata[0].id === 0) {
                                    window.console.log('======= userid 获取失败  ========')
                                    localStorage.setItem('UserId',0)
                                    that.$router.push({path: 'Regedit'})
                                } else {
                                    window.console.log('======= 已获取userid  ========')
                                    localStorage.setItem("UserId",tmpdata[0].id.Int64)
                                    localStorage.setItem("UserName",tmpdata[0].parentname.String)
                                    Notify({ type: 'success', message: '您好,' + tmpdata[0].parentname.String });
                                }
                            } else {
                                window.console.log('======= userid 获取失败  ========')
                                localStorage.setItem('UserId',0)
                                that.$router.push({path: 'Regedit'})
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })

            },
            onSKRecLoad() {
                // var qstr = 'teacherid = 29 and weekid = ' + that.weekid
                var qstr = 'teacherid = ' + localStorage.getItem('TeacherId') + ' and weekid = ' + that.weekid
                that.$http({
                    url: 'https://api.sqnetsoft.cn/course/qbcs',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            window.console.log('已成功获取排课记录')
                            that.sklist = JSON.parse(res.data.data)
                            window.console.log(that.sklist)
                            that.refreshing = false
                            that.finished = true
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
                /*
                setTimeout(() => {
                    if (this.refreshing) {
                        this.list = [];
                        this.refreshing = false;
                    }
                    for (let i = 0; i < 10; i++) {
                        this.list.push(this.list.length + 1);
                        this.finished = true;
                    }
                    this.loading = false;

                    if (this.list.length >= 40) {
                        this.finished = true;
                    }
                }, 1000);
                 */
            },
            onRefresh() {
                // 清空列表数据
                // 重新加载数据
                this.refreshing = true
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onSKRecLoad();
            },
            returnyw () {
                this.$router.push({path: '/'})
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                return `${date.getFullYear()}-${monthstr}-${date.getDate()}`;
            },
            onConfirm(date) {
                this.rqshow = false;
                window.console.log(date)
                this.date = this.formatDate(date);
                var myDate = new Date(date);
                var days = myDate.getDay();
                window.console.log(days)
                that.weekid = days
                that.$options.methods.onSKRecLoad();
            }
        }
    }
</script>

<style scoped>

</style>