<template>
    <div>
        <div class="userinfo">
            <img class="userinfo-avatar" :src="userimg" />
            <div>{{usernick}}</div>
        </div>
        <div class="rowslip"></div>

        <div class='paywayinfo'>
            <div class="paywaypanel" bindtap="showSpecHide">
                <div class="paywayicon">
                    <text class="icondingdan fontbc"></text>
                </div>
                <div class="paywaycontext">
                    教学信息
                </div>
                <div class="paywayend"></div>
            </div>
        </div>
        <div class="orderview1">
            <div class="orderviewitem1">
                <div class="dfkpanel">
                    <i class="icondaifukuan2 dfkicon 4x"></i>
                    <div v-if="dfkcount > 0">
                        <sup>{{dfkcount}}</sup>
                    </div>
                    <div class="ordertxt1" >本月课次</div>
                </div>
            </div>
            <div class="colslip"></div>
            <div class="orderviewitem1">
                <div class="dfkpanel">
                    <i class="iconyifukuan dfkicon 4x"></i>
                    <div v-if="ygmcount > 0">
                        <sup>{{ygmcount}}</sup>
                    </div>
                    <div class="ordertxt1" >本月收入</div>
                </div>
            </div>
        </div>
        <div class='paywayinfo'>
            <div class="paywaypanel" @touchend="makecode" @click="makecode">
                <div class="paywayicon">
                    <text class="iconlianxikefu fontbc"></text>
                </div>
                <div class="paywaycontext">
                    生成分享二维码
                </div>
                <div class="paywayend">></div>
            </div>
        </div>
        <div class='paywayinfo'>
            <div class="paywaypanel" @touchend="sysSend">
                <div class="paywayicon">
                    <text class="iconlianxikefu fontbc"></text>
                </div>
                <div class="paywaycontext">
                    我的消息
                </div>
                <div class="paywayend">></div>
            </div>
        </div>
        <div class='paywayinfo'>
            <div class="paywaypanel" @touchend="clearhc">
                <div class="paywayicon">
                    <text class="iconlianxikefu fontbc"></text>
                </div>
                <div class="paywaycontext">
                    清空缓存
                </div>
                <div class="paywayend">></div>
            </div>
        </div>
        <div class='paywayinfo'>
            <div class="paywaypanel" @touchend="sysSet">
                <div class="paywayicon">
                    <text class="iconlianxikefu fontbc"></text>
                </div>
                <div class="paywaycontext">
                    设置
                </div>
                <div class="paywayend">></div>
            </div>
        </div>
        <div class="rowslip"></div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import '../../static/css/my.css'
    import '../../static/font/iconfont.css'
    import axios from 'axios'
    import { Lazyload } from 'vant'
    import { Notify } from 'vant';

    Vue.use(Notify);
    Vue.use(Lazyload)
    Vue.prototype.$http = axios
    var that
    export default {
        name: 'My',
        data () {
            return {
                userinfo: '',
                userimg: '',
                usernick: '',
                dfkcount: 0,
                ygmcount: 0,
                dfkdata: [],
                ygmdata:[],
                ifmemberinfo: '开通会员',
                vipinfo: 'VIP'
            }
        },
        mounted: function () {
            that = this
            if (localStorage.getItem('userinfo') === null || localStorage.getItem('userinfo') === '') {
                that.$options.methods.getuserinfo()
            } else {
                var tmpdata = JSON.parse(localStorage.getItem('userinfo'))
                that.userimg = tmpdata.headimgurl
                that.usernick = tmpdata.nickname
                if (localStorage.getItem('TeacherId') === null || localStorage.getItem('TeacherId') === '0') {
                    that.$options.methods.checkuser()
                }
            }
            stopTouchendPropagationAfterScroll()
        },
        methods: {
            clearhc () {
                localStorage.clear()
                clearAllCookie()
                that.$router.push({ path: '/' })
                Notify({ type: 'success', message: '清空完毕' });
            },
            sysSet () {
                that.$router.push({ path: 'MySet' })
            },
            sysSend () {
                that.$router.push({ path: 'MyMsg' })
            },
            checkuser: function () {
                // 依照openid来查看有没有对应用户
                var qstr1 = "openid = '" + localStorage.getItem('openid') + "'"
                that.$http({
                    url: 'https://api.sqnetsoft.cn/member/gbuw',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr1, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data.toString() === '[]') {
                                that.$router.push('/Regedit')
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })

            },
            getuserinfo: function () {
                that.$http({
                    url: 'https://api.sqnetsoft.cn/wx/getuserinfo',
                    method: 'post',
                    data: {
                        accesstoken: getCookie('accesstoken'),
                        openid: localStorage.getItem('openid')
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            var tmpdata = JSON.parse(res.data.data)
                            if (tmpdata) {
                                localStorage.setItem('userinfo',res.data.data)
                                that.userimg = tmpdata.headimgurl
                                that.usernick = tmpdata.nickname
                                if (localStorage.getItem('TeacherId') === null || localStorage.getItem('TeacherId') === '0') {
                                    that.$options.methods.checkuser()
                                }
                            } else {
                                localStorage.setItem('userinfo','')
                            }
                            window.console.log(res)
                            // 把相关数据缓存到本地
                        } else {
                            localStorage.setItem('userinfo','')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            makecode: function () {
                if (localStorage.getItem('ticket') === null || localStorage.getItem('ticket') === '') {
                    that.$http({
                        url: 'https://api.sqnetsoft.cn/wx/mc',
                        method: 'post',
                        data: {
                            accesstoken: getCookie('accesstoken'),
                            actionname: 'QR_LIMIT_STR_SCENE',
                            actioninfo: {
                                scene: {scene_str: localStorage.getItem('openid')}
                            },
                            color: '#000000'
                        },
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.success) {
                                // 把相关数据缓存到本地
                                var tmpdata = JSON.parse(res.data.data)
                                localStorage.setItem('ticket',tmpdata.ticket)
                                that.$router.push({ path: 'SharePage' })
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    that.$router.push({ path: 'SharePage' })
                }

            }
        }
    }
    function getCookie (name) {
        var v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
        return v ? v[2] : null
    }
    function stopTouchendPropagationAfterScroll(){
        var locked = false;
        window.addEventListener('touchmove', function(ev){
            locked || (locked = true, window.addEventListener('touchend', stopTouchendPropagation, true));
            window.console.log(ev)
        }, true);
        function stopTouchendPropagation(ev){
            ev.stopPropagation();
            window.removeEventListener('touchend', stopTouchendPropagation, true);
            locked = false;
        }
    }
    function clearAllCookie() {
        var keys = document.cookie.match(/[^ =;]+(?==)/g)
        if (keys) {
            for (var i = keys.length; i--;) {
                document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString() // 清除当前域名下的,例如：m.ratingdog.cn
                document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString() // 清除当前域名下的，例如 .m.ratingdog.cn
                document.cookie = keys[i] + '=0;path=/;domain=ratingdog.cn;expires=' + new Date(0).toUTCString() // 清除一级域名下的或指定的，例如 .ratingdog.cn
            }
        }
    }
</script>