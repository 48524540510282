<template>
    <div>
        <van-nav-bar
                :title="'我的评价(' + pjrq + ')'"
                left-text="返回"
                right-text="日期选择"
                left-arrow
                @click-left="returnyw"
                @click-right="show = true"
        />
        <van-calendar v-model="show" @confirm="onConfirm" />
        <van-tabs v-model="active">
            <van-tab title="待评价">
                <van-collapse v-model="activeName" accordion v-for="item in pjbjlist" :key="item">
                    <van-collapse-item :title="'班级名：' + item.cname.String + ', 时间段：' + item.weektime.String " name="1">
                        <van-cell v-for="item1 in item.items" :key="item1"  >
                            <van-grid>
                                <van-grid-item :text="item1.userphone" @click="showsc(item1)" />
                                <van-grid-item :text="item1.childname.String" @click="showsc(item1)" />
                            </van-grid>
                        </van-cell>
                    </van-collapse-item>
                </van-collapse>
            </van-tab>
            <van-tab title="已评价"></van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Collapse, CollapseItem } from 'vant';
    import { Notify } from 'vant';
    import axios from 'axios'
    Vue.prototype.$http = axios
    Vue.use(Notify);
    Vue.use(Collapse);
    Vue.use(CollapseItem);
    var that
    export default {
        name: "MyPJ",
        data() {
            return {
                date: '',
                show: false,
                pjrq: '',
                pjbjlist: [],
                bjxylist: [],
                activeName: ['1'],
                weekinfo: ''
            }
        },
        mounted: function () {
            that = this
            that.pjrq = this.formatDate(new Date());
            var tmpdata = new Date(that.pjrq)
            that.weekinfo = tmpdata.getDay()
            that.$options.methods.checkuserid()
            that.$options.methods.querypkbj()
            that.$options.methods.querybjxy()
        },
        methods: {
            checkuserid: function () {
                window.console.log('======= 正在检测userid  ========')
                var tmpdata = JSON.parse(localStorage.getItem('userinfo'))
                that.userimg = tmpdata.headimgurl
                that.usernick = tmpdata.nickname
                if (localStorage.getItem('UserId') === null || localStorage.getItem('UserId') === '0') {
                    window.console.log('======= userid 获取失败  ========')
                    that.$options.methods.checkuser()
                } else {
                    window.console.log('======= userid 已获取  ========')
                    Notify({ type: 'success', message: '您好,' + localStorage.getItem('UserName') });
                }
            },
            checkuser: function () {
                // 依照openid来查看有没有对应用户
                var qstr1 = "openid = '" + localStorage.getItem('openid') + "'"
                that.$http({
                    url: 'https://api.sqnetsoft.cn/member/gbmu',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr1, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data.toString() !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                if (tmpdata[0].id === 0) {
                                    window.console.log('======= userid 获取失败  ========')
                                    localStorage.setItem('UserId',0)
                                    that.$router.push({path: 'Regedit'})
                                } else {
                                    window.console.log('======= 已获取userid  ========')
                                    localStorage.setItem("UserId",tmpdata[0].id.Int64)
                                    localStorage.setItem("UserName",tmpdata[0].parentname.String)
                                    Notify({ type: 'success', message: '您好,' + tmpdata[0].parentname.String });
                                }
                            } else {
                                window.console.log('======= userid 获取失败  ========')
                                localStorage.setItem('UserId',0)
                                that.$router.push({path: 'Regedit'})
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })

            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                return `${date.getFullYear()}-${monthstr}-${date.getDate()}`;
            },
            onConfirm(date) {
                this.show = false;
                this.pjrq = this.formatDate(date);
                var tmpdata = new Date(that.pjrq)
                that.weekinfo = tmpdata.getDay()
                that.$options.methods.querypkbj()
                that.$options.methods.querybjxy()
            },
            querypkbj () {
                // 获取讲师负责的当日班级
                // var qstr = 'teacherid = ' + localStorage.getItem('TeacherId') + ' and  weekid = ' + that.weekinfo
                var qstr = 'teacherid = 29 and  weekid = ' + that.weekinfo
                that.$http({
                    url: 'https://api.sqnetsoft.cn/course/qbcs',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                var qstr = 'weekid = ' + that.weekinfo
                                that.$http({
                                    url: 'https://api.sqnetsoft.cn/class/qbcs',
                                    method: 'post',
                                    data: {
                                        whereinfo: {String: qstr, Valid: true}
                                    },
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                })
                                    .then(function (res) {
                                        if (res.data.success) {
                                            that.pjbjlist = []
                                            if (res.data.data !== '[]') {
                                                that.bjxylist = JSON.parse(res.data.data)
                                                for (var i = 0;i < tmpdata.length; i++) {
                                                    var tmpdata1 = []
                                                    for (var j = 0;j < that.bjxylist.length; j++) {
                                                        if (tmpdata[i].classid === that.bjxylist[j].classid) {
                                                            tmpdata1.push(that.bjxylist[j])
                                                        }
                                                    }
                                                    tmpdata[i].items = tmpdata1
                                                    that.pjbjlist.push(tmpdata[i])
                                                }
                                            } else {
                                                that.pjbjlist = tmpdata
                                            }
                                            window.console.log(that.pjbjlist)
                                        }
                                    })
                                    .catch(function (error) {
                                        window.console.log('error' + error)
                                    })
                            }

                            that.pjbjlist = JSON.parse(res.data.data)
                            window.console.log(that.pjbjlist)
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            querybjxy () {
                // 获取班级学员
                var qstr = 'weekid = ' + that.weekinfo
                that.$http({
                    url: 'https://api.sqnetsoft.cn/class/qbcs',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            that.bjxylist = JSON.parse(res.data.data)
                            window.console.log(that.bjxylist)
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            returnyw () {
                this.$router.push({path: '/'})
            },
            showsc: function (item) {
                window.console.log(item)
                localStorage.setItem("curphone",item.userphone)
                this.$router.push({path: 'MyUpLoad'})
            }
        }
    }
</script>

<style scoped>

</style>