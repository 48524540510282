import { render, staticRenderFns } from "./SFWay.vue?vue&type=template&id=509cf915&scoped=true&"
import script from "./SFWay.vue?vue&type=script&lang=js&"
export * from "./SFWay.vue?vue&type=script&lang=js&"
import style0 from "./SFWay.vue?vue&type=style&index=0&id=509cf915&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "509cf915",
  null
  
)

export default component.exports