<template>
    <div>
        <div class="van-doc-demo-block__title">
            关系列表
        </div>
        <div style="padding: 10px;" id="mymainChart" class="chartcard">
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import axios from 'axios'
    // import Utils from '../../util/utils'
    Vue.prototype.$http = axios
    var that
    var myChart
    export default {
        name: "MyRelation",
        data () {
            return {
                enames: [
                    {
                        name: '测试用户'
                    },
                    {
                        name: '测试用户'
                    },
                    {
                        name: '测试用户'
                    },
                    {
                        name: '测试用户'
                    },
                    {
                        name: '测试用户'
                    }
                ]
            }
        },
        mounted () {
            that = this
            that.loadsubs()
        },
        methods: {
            loadsubs () {
                var qstr = {
                    whereinfo: " openid='" + localStorage.getItem('openid') + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryrelation',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            that.drawLine(localStorage.getItem('nickname'),res.data)
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            drawLine (sname,enames) {
                // 基于准备好的dom，初始化echarts实例
                myChart = this.$echarts.init(document.getElementById('mymainChart'))
                var pdata = [
                    {
                        name: sname,
                        x: 300,
                        y: 300
                    }
                ]
                var ldata = []
                for (var i = 0; i < enames.length; i++) {
                    var tmpdata = {
                        name: enames[i].realname + '(' + parseInt(i+1) + ')',
                        x: 500,
                        y: 100 + i * 200
                    }
                    var tmpdata1 = {
                        source: sname,
                        target: enames[i].realname + '(' + parseInt(i+1) + ')'
                    }
                    pdata.push(tmpdata)
                    ldata.push(tmpdata1)
                }
                window.console.log(pdata)
                window.console.log(ldata)
                // 绘制图表
                myChart.setOption({
                    title: {
                        text: ''
                    },
                    tooltip: {},
                    animationDurationUpdate: 1500,
                    animationEasingUpdate: 'quinticInOut',
                    series: [
                        {
                            type: 'graph',
                            layout: 'none',
                            symbolSize: 20,
                            roam: true,
                            label: {
                                show: true
                            },
                            edgeSymbol: ['circle', 'arrow'],
                            edgeSymbolSize: [4, 10],
                            edgeLabel: {
                                fontSize: 20
                            },
                            data: pdata,
                            // links: [],
                            links: ldata,
                            lineStyle: {
                                opacity: 0.9,
                                width: 2,
                                curveness: 0
                            }
                        }
                    ]
                })
                window.onresize = myChart.resize
            }
        }
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
    .chartcard {
        height: 280px;
        padding: 2px;
    }
</style>
