<template>
    <div>
        <van-nav-bar
                title="作业管理"
                left-text="返回"
                left-arrow
                @click-left="returnyw">
            <van-icon name="add-o" slot="right" @click="showaddqj = true"/>
        </van-nav-bar>
        <van-tabs v-model="active">
            <van-tab title="已完成">
                <van-panel :title="username" :desc="update" :status="upstate" :icon="iconsrc">
                    <van-grid>
                        <van-grid-item icon="photo-o" text="文字" />
                        <van-grid-item icon="photo-o" text="文字" />
                        <van-grid-item icon="photo-o" text="文字" />
                        <van-grid-item icon="photo-o" text="文字" />
                    </van-grid>
                </van-panel>
            </van-tab>
            <van-tab title="待完成">
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Step, Steps } from 'vant';
    import { Panel } from 'vant';

    Vue.use(Panel);
    Vue.use(Step);
    Vue.use(Steps);
    export default {
        name: "MyHomeWork",
        data () {
            return {
                iconsrc:'http://thirdwx.qlogo.cn/mmopen/K6CEv0Hv9Ddd1xMndNuACAgDMUMz1CtUqHCDKOmIL07hw6tiaS5Kzul3x2MDyS3ZyCc0DqHXpngDjpku1iadvDog/132',
                username: '晓朔',
                update:'2020-03-01',
                upstate:'已完成'
            }
        }
    }
</script>

<style scoped>
    .van-cell__left-icon img {
        width: 42px;
        height: 42px;
        font-size: 16px;
        line-height: 24px;
        border-radius: 50%;
        border: 2px solid #fff;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    }
</style>