<template>
    <div>
            <van-tabs v-model="active">
            <van-tab title="待财务确认业绩" >
                <div>
                    <div class="van-doc-demo-block__title">
                        业绩查询
                    </div>
                    <van-field
                            readonly
                            clickable
                            name="datetimePicker"
                            :value="sjstartvalue"
                            label="开始时间"
                            placeholder="点击选择时间"
                            @click="showPicker1 = true"
                    />
                    <van-popup v-model="showPicker1" position="bottom">
                        <van-datetime-picker
                                type="date"
                                v-model="currentDate"
                                @confirm="onSJConfirm"
                                @cancel="showPicker1 = false"
                        />
                    </van-popup>
                    <van-field
                            readonly
                            clickable
                            name="datetimePicker"
                            :value="sjendvalue"
                            label="结束时间"
                            placeholder="点击结束时间"
                            @click="showPicker2 = true"
                    />
                    <van-popup v-model="showPicker2" position="bottom">
                        <van-datetime-picker
                                type="date"
                                v-model="currentDate"
                                @confirm="onSJEndConfirm"
                                @cancel="showPicker2 = false"
                        />
                    </van-popup>
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit" @click="queryorder">
                            查询业绩
                        </van-button>
                    </div>
                    <div class="van-doc-demo-block__title">
                        业绩列表
                    </div>
                    <div v-for="(item,index) in orderlist" :id="'item' + index" :key="index" ref='yjlist'>
                        <van-cell-group>
                            <van-cell icon=" iconfont iconliebiao" is-link value="修改" @click="showorder(index)">
                                <template #default>
                                    <div>
                                        <div>学员姓名： {{item.xyname}}</div> <div>付款金额： {{item.cprice}} ,付费日期： {{item.orderdate}}</div>
                                    </div>
                                </template>
                            </van-cell>
                        </van-cell-group>
                    </div>
                </div>
            </van-tab>
            <van-tab title="已审核">
                <div class="van-doc-demo-block__title">
                    已确认业绩
                </div>
                <div v-for="(item,index) in shorderlist" :key="index">
                    <van-cell icon=" iconfont iconliebiao" is-link value="修改" @click="showshorder(index)">
                        <template #default>
                            <div>
                                <div>学员姓名： {{item.xyname}}</div> <div>付款金额： {{item.cprice}} ,付费日期： {{item.orderdate}}</div>
                            </div>
                        </template>
                    </van-cell>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Card } from 'vant';
    import { Icon } from 'vant';
    import { Pagination } from 'vant';
    import { Toast } from 'vant'
    import { RadioGroup, Radio } from 'vant';
    import { Picker,Popup } from 'vant';
    import { DatetimePicker } from 'vant';
    Vue.use(Pagination);
    Vue.use(Icon);
    Vue.use(Card);
    Vue.use(Toast)
    Vue.use(Popup,Picker);
    Vue.use(DatetimePicker);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    var that
    export default {
        name: "MyXD",
        data () {
            return {
                showPicker1: false,
                showPicker2: false,
                showPicker3: false,
                showPicker4: false,
                sjstartvalue: '',
                currentDate: '',
                active: 2,
                sjendvalue: '',
                xqlist: [],
                xqid: '',
                xq: '',
                jl: '',
                xqidlist: [],
                fromname: '',
                jllist: [],
                jlid: '',
                jlidlist: [],
                orderlist: [],
                shorderlist: []
            }
        },
        mounted() {
            that = this
            window.console.log(that)
            that.currentDate = new Date()
            that.getshorder()
            that.queryorder()
            document.title = '我的业绩'
        },
        beforeRouteEnter (to, from, next) {
            window.console.log(to)
            window.console.log(from)
            localStorage.setItem('fromname',from.name)
            window.console.log(next)
            next();
        },
        methods: {
            getshorder () {
                var qdata = {
                    whereinfo: ' orderstate = 2 and adduser = ' + localStorage.getItem('loginid'),
                    limit: '',
                    sortinfo: 'order by rqdate desc'
                }
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryorder',
                    method: 'post',
                    data:qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.shorderlist = res.data
                        window.console.log(that.shorderlist)
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getxqlist () {
                var qstr = 'id > 0'
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryxq',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.xqlist.push(tmpdata[i].xqname)
                                that.xqidlist.push(tmpdata[i].id)
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getjllist () {
                var qstr = "userid > 0 and postname like '%教练%'"
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryjlinfo',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.jllist.push(tmpdata[i].realname)
                                that.jlidlist.push(tmpdata[i].userid)
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                var daystr = ''
                if(parseInt(date.getDate() + 1) <= 10) {
                    daystr = '0' + parseInt(date.getDate())
                } else {
                    daystr = date.getDate()
                }
                return `${date.getFullYear()}-${monthstr}-${daystr}`;
            },
            onSJConfirm (date) {
                this.sjstartvalue = this.formatDate(date);
                this.showPicker1 = false;
            },
            onSJEndConfirm (date) {
                this.sjendvalue = this.formatDate(date);
                this.showPicker2 = false;
            },
            onxqConfirm (value,index) {
                window.console.log(value)
                that.xq = that.xqlist[index]
                window.console.log(that.xq)
                that.xqid = that.xqidlist[index]
                that.showPicker3 = false
            },
            showorder (index) {
                localStorage.setItem('sorderinfoindex',index)
                localStorage.setItem('sorderinfo',JSON.stringify(that.orderlist[index]))
                that.$router.push({path: 'ShowXD_Edit1'})
            },
            showshorder (index) {
                localStorage.setItem('nosorderinfoindex',index)
                localStorage.setItem('sorderinfo',JSON.stringify(that.shorderlist[index]))
                that.$router.push({path: 'ShowOrderNoSH'})
            },
            onjlConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.jl = that.jllist[index]
                window.console.log(that.jl)
                window.console.log(that.jlidlist)
                that.jlid = that.jlidlist[index]
                that.showPicker4 = false
            },
            queryorder () {
                var qstr = " orderstate = '1' and adduser = '" + localStorage.getItem('loginid') + "'"
                if (this.sjstartvalue !== null && this.sjstartvalue !== '' && this.sjendvalue !== null && this.sjendvalue !== '') {
                    qstr = qstr + " and adddate >= '" + this.sjstartvalue + " 00:00:00' and adddate<='" + this.sjendvalue + " 23:59:59'"
                }
                var qdata = {
                    whereinfo: qstr,
                    limit: '',
                    sortinfo: ' order by adddate desc'
                }
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryorder',
                    method: 'post',
                    data:qdata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        that.orderlist = res.data
                        if (localStorage.getItem('fromname') === 'ShowXD_Edit1') {
                            if (localStorage.getItem('sorderinfoindex') !== null) {
                                that.$nextTick(() => {
                                    document.getElementById('item' + localStorage.getItem('sorderinfoindex')).scrollIntoView()
                                })
                            }
                        }
                        window.console.log(that.orderlist)
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            }
        }
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
