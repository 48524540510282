<template>
    <div style="background-color: #f7f8fa;">
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                基础信息
            </div>
            <van-form>
                <van-count-down
                        ref="countDown"
                        hidden
                        second
                        time="30000"
                        :auto-start="false"
                        format="ss"
                        @change = "changetime"
                        @finish="finishok"
                />
                <van-field
                        v-model="phone"
                        name="手机号"
                        type="tel"
                        label="手机号"
                        placeholder="手机号"
                        :rules="[{ required: true, message: '请填写手机号' }]"
                />
                <van-field
                        v-model="sms"
                        center
                        clearable
                        label="验证码"
                        placeholder="请输入短信验证码"
                >
                    <van-button slot="button" ref="countDownBtn" size="small" :disabled="yzmabled" @touchend="getcode" @click="getcode" type="primary">
                        {{btntxt}}
                    </van-button>
                </van-field>
                <van-field
                        v-model="parentname"
                        name="监护人"
                        label="监护人"
                        placeholder="监护人"
                />
            </van-form>
        </div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                完善孩子信息
            </div>
            <van-form @submit="onSubmit">
                <van-field
                        v-model="childname"
                        name="姓名"
                        label="姓名"
                        placeholder="孩子姓名"
                        :rules="[{ required: true, message: '请填写姓名' }]"
                />
                <van-field
                        v-model="childnick"
                        name="昵称"
                        label="昵称"
                        placeholder="孩子昵称"
                        :rules="[{ required: true, message: '请填写孩子昵称' }]"
                />
                <van-field
                        readonly
                        clickable
                        name="picker"
                        :value="cbirthday"
                        label="出生日期"
                        placeholder="点击选择出生日期"
                        @click="showPicker = true"
                />
                <van-popup v-model="showPicker" position="bottom">
                    <van-datetime-picker
                            v-model="currentDate"
                            type="date"
                            :min-date="minDate"
                            :max-date="maxDate"
                            @confirm="dt_select"
                    />
                </van-popup>

                <div style="margin: 16px;">
                    <van-button round block type="info" native-type="submit" @touchend="onSubmit" @click="onSubmit">
                        立即注册
                    </van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Picker } from 'vant';
    import { Popup } from 'vant';
    import { CountDown } from 'vant';
    import { Notify } from 'vant';

    Vue.use(Notify);
    Vue.use(CountDown);
    import Utils from '../util/utils'
    Vue.use(Popup);
    Vue.use(Picker);
    Vue.use(Field);
    Vue.use(Form);
    var that
    export default {
        name: "Regedit",
        data() {
            return {
                phone: '',
                sms: '',
                userimg: '',
                usernick: '',
                childnick: '',
                jsname: '',
                value: '',
                jsdes: '',
                parentname: '',
                childname: '',
                typeid: '',
                yzmabled: false,
                minDate: new Date(2000, 9, 1),
                maxDate: new Date(2020, 10, 1),
                currentDate: new Date(),
                typevalue: '',
                smscode: '',
                teacher_type_list: [],
                cbirthday: '',
                teacher_type_idlist: [],
                showPicker: false,
                btntxt: '发送验证码'
            };
        },
        mounted: function () {
            that = this
            document.title = '完善信息'
            var qstr = 'cusid =1'
            that.$http({
                url: 'https://api.sqnetsoft.cn/teacher/qtt',
                method: 'post',
                data: {
                    whereinfo: {String: qstr, Valid: true},
                    sorinfo: {String: ' order by id desc', Valid: true}
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(function (res) {
                    if (res.data.success) {
                        window.console.log('已成功获取讲师数据')
                        if (res.data.data !== '[]') {
                            var tmpdata = JSON.parse(res.data.data)
                            for (var i=0;i<tmpdata.length;i++) {
                                that.teacher_type_list.push(tmpdata[i].name.String)
                                that.teacher_type_idlist.push(tmpdata[i].id.Int64)
                            }
                        } else {
                            that.teacher_type_list = []
                            that.teacher_type_idlist = []
                        }
                    }
                })
                .catch(function (error) {
                    window.console.log('error' + error)
                })
        },
        methods: {
            changetime (val) {
                this.btntxt = '等待 ' + val.seconds + ' 秒'
            },
            finishok () {
                Notify({ type: 'warning', message: '倒计时结束' });
                this.yzmabled = false
                this.btntxt = '发送验证码'
            },
            getcode () {
                var reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
                if (this.phone !== '' && reg.test(this.phone)) {
                    window.console.log(this)
                    that.$refs.countDown.start()
                    this.yzmabled = true
                    var datatmp = {
                        vphone: this.phone,
                        issend: parseInt(0)
                    }
                    that.$http({
                        url: 'https://api.sqnetsoft.cn/account/getsms',
                        method: 'post',
                        data: datatmp,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.success) {
                                // 把相关数据缓存到本地
                                window.console.log(res)
                                that.smscode = res.data.data
                                that.sms = res.data.data
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    Notify({ type: 'warning', message: '手机号输入不正确' });
                }
            },
            onSubmit(values) {
                window.console.log('submit', values);
                var datatmp1 = new Date(this.cbirthday)
                var tmpdata2 = new Date()
                if (this.phone !== '' && this.sms !== '' && this.sms === this.smscode && (localStorage.getItem('openid') !== null && localStorage.getItem('openid') !== '')) {
                    var tmpdata = JSON.parse(localStorage.getItem('userinfo'))
                    this.userimg = tmpdata.headimgurl
                    this.usernick = tmpdata.nickname

                    let addtime = Utils.dateFormat(new Date(), 'yyyy-MM-dd hh:mm:ss') // 当前时间
                    var adddata1
                    adddata1 = {
                        userphone: {String: this.phone, Valid: true},
                        cname: {String: this.childname, Valid: true},
                        cnick: {String: this.childnick, Valid: true},
                        birthday: {String: this.cbirthday, Valid: true},
                        age: parseInt(tmpdata2.getFullYear() - datatmp1.getFullYear()),
                        openid: {String: localStorage.getItem('openid'), Valid: true},
                        cdes: {String: '', Valid: true},
                        phone: {String: this.phone, Valid: true},
                        parentname: {String: this.parentname, Valid: true},
                        addtime: {String: addtime, Valid: true},
                        cusid: parseInt(1)
                    }


                    that.$http({
                        url: 'https://api.sqnetsoft.cn/member/abmu',
                        method: 'post',
                        data: adddata1,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.success) {
                                that.$router.push('/')
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    Notify({ type: 'warning', message: '信息不完整，请检查' });
                }

            },
            formatSecond(date) {
                return `${date.getSeconds()}`;
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                return `${date.getFullYear()}-${monthstr}-${date.getDate()}`;
            },
            dt_select (val) {
                this.showPicker = false;
                this.cbirthday = this.formatDate(val)
            },
            onConfirm(value,index) {
                this.typevalue = value
                this.typeid = this.teacher_type_idlist[index]
                this.showPicker = false;
            }
        }
    }
</script>

<style scoped>
    body {
        min-width: 1100px;
        margin: 0;
        overflow-x: auto;
        color: #323233;
        font-size: 16px;
        font-family: PingFang SC, 'Helvetica Neue', Arial, sans-serif;
        background-color: #f7f8fa;
        -webkit-font-smoothing: antialiased;
    }
    .van-doc-demo-block:first-of-type .van-doc-demo-block__title {
        padding-top: 20px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 32px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
