<template>
    <div>
        <img style="height: 220px;width:220px; margin-top:40px; object-fit: cover;" :src="codeurl" />
    </div>
</template>

<script>
    import Vue from 'vue'
    import '../../static/css/my.css'
    import '../../static/font/iconfont.css'
    import axios from 'axios'
    import { Lazyload } from 'vant'
    Vue.use(Lazyload)
    Vue.prototype.$http = axios
    var that
    export default {
        name: "SharePage",
        data() {
            return {
                codeurl: ''
            }
        },
        mounted: function () {
            that = this
            that.codeurl = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=gQGC8TwAAAAAAAAAAS5odHRwOi8vd2VpeGluLnFxLmNvbS9xLzAyb0hiRkZBM3pjQkMxMDAwMDAwN24AAgTCG-xdAwQAAAAA'
            window.console.log(that.codeurl)
            // that.codeurl = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + localStorage.getItem('ticket')
        },
        methods: {
        }
    }
</script>

<style scoped>

</style>