<template>
    <div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                课时信息
            </div>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="tdinfo"
                    label="教练员团队"
                    placeholder="点击选择团队"
            />
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="jlname"
                    label="任课教练"
                    placeholder="任课教练"
            />
            <van-field
                    readonly
                    clickable
                    name="datetimePicker"
                    :value="orderdate"
                    label="上课日期"
                    placeholder="点击上课日期"
            />
            <van-cell>
                <!-- 使用 title 插槽来自定义标题 -->
                <template #extra>
                    <div style="display: flex;justify-content: center;align-items: center;flex-flow: row;">
                        <div>时间段</div>
                        <div style="margin-left: 45px;">
                            <el-time-select
                                    disabled
                                    v-model="starttime"
                                    :picker-options="{
                                start: '09:00',
    step: '00:30',
    end: '20:30'
    }"
                                    placeholder="任意时间点">
                            </el-time-select> ---
                            <el-time-select
                                    disabled
                                    arrow-control
                                    v-model="endtime"
                                    :picker-options="{
      start: '09:00',
    step: '00:30',
    end: '20:30'
    }"
                                    placeholder="任意时间点">
                            </el-time-select>
                        </div>
                    </div>
                </template>
            </van-cell>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="xq"
                    label="上课校区"
                    placeholder="点击选择校区"
            />
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="cd"
                    label="占用区域"
                    placeholder="点击选择占用区域"
            />
            <van-field name="radio" label="占用区域占用">
                <template #input>
                    <van-radio-group v-model="cdiffull" direction="horizontal">
                        <van-radio name="0" disabled >半场训练</van-radio>
                        <van-radio name="1" disabled >整场训练</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="kc"
                    label="课程类型"
                    placeholder="点击选择课程类型"
            />
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="bz"
                    label="班组选择"
                    placeholder="点击选择班组选择"
            />
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="members"
                    label="学员选择"
                    placeholder="请选择本次上课的学员"
            />
            <van-field label="合影拍照">
                <template #input>
                    <viewer :images="photo" >
                        <img
                                style="width:220px;height:220px;"
                                v-for="(src,index) in hyimgs"
                                :src="src"
                                :key="index"
                        >
                    </viewer>
                </template>
            </van-field>
            <van-field
                    v-model="ksdes"
                    rows="3"
                    autosize
                    label="描述"
                    type="textarea"
                    show-word-limit
                    placeholder="备注"
            />
            <div style="margin: 16px;margin-bottom: 20px;">
                <van-button round block :disabled="disablesh" type="info" native-type="submit" @click="shqr">
                    审核确认
                </van-button>
                <van-overlay :show="disablesh" @click="disablesh = false" />
            </div>
            <van-dialog v-model="showshqr" title="审核确认" @confirm="qrshok" show-cancel-button>
                <div class="van-doc-demo-block">
                    <div class="van-doc-demo-block__title">
                        <div>{{jlname}}的 本次销课总额： {{xkprices.toFixed(0)}} </div>
                    </div>
                </div>
                <div style="height: 400px; overflow-y:auto;">
                    <div v-for="(item,index) in selmembers" :key="index" style="display: flex;flex-flow: column; font-size:12px; margin-top: 10px; margin-left: 20px; margin-right: 20px;">
                        <div>学员：{{item.xyname}} | 类别： {{item.mtypename}}</div>
                        <div v-if="item.kctypename.includes('VIP') || item.kctypename.includes('封闭')">
                            <div v-if="item.mcount === '-1'">
                                <div style="margin: 10px;">
                                    <div>到期日期：{{item.enddate}}</div>
                                    <div>上次消费日期：{{item.kfdate}}</div>
                                    <div>上次结余金额：{{item.sycount}} 元</div>
                                    <div>距上次消费：{{item.xhdays}} 天</div>
                                    <div>日单价：{{item.xfdj}} 元</div>
                                    <div>本地消费：{{item.bcxf}} 元</div>
                                    <div>余额：{{item.syfy}} 元</div>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="item.sycount < 1">
                                    <div style="color:red;">卡次已消耗完毕，消耗赠卡</div>
                                    <div>到期日期：{{item.enddate}}</div>
                                    <div>上次消费日期：{{item.kfdate}}</div>
                                    <div>上次剩余次数：{{item.zscount}} 次</div>
                                    <div>本次消费：{{item.bcxf}} 元</div>
                                    <div>剩余次数：{{item.sytimes}} 次</div>
                                </div>
                                <div v-else>
                                    <div>到期日期：{{item.enddate}}</div>
                                    <div>上次消费日期：{{item.kfdate}}</div>
                                    <div>上次剩余次数：{{item.sycount}} 次</div>
                                    <div>本次消费：{{item.bcxf}} 元</div>
                                    <div>剩余次数：{{item.sytimes}} 次</div>
                                </div>
                            </div>
                        </div>
                        <div v-else-if="item.kctypename.includes('特训')">
                            <div style="margin: 10px;">
                                <div v-if="item.sycount < 1">
                                    <div style="color:red;">特训</div>
                                    <div style="color:red;">卡次已消耗完毕，消耗赠卡</div>
                                    <div>到期日期：{{item.enddate}}</div>
                                    <div>上次消费日期：{{item.kfdate}}</div>
                                    <div>上次剩余次数：{{item.zscount}} 次</div>
                                    <div>本次消费：{{item.bcxf}} 元</div>
                                    <div>剩余次数：{{item.sytimes}} 次</div>
                                </div>
                                <div v-else>
                                    <div style="color:red;">特训</div>
                                    <div>到期日期：{{item.enddate}}</div>
                                    <div>上次消费日期：{{item.kfdate}}</div>
                                    <div>上次剩余次数：{{item.sycount}} 次</div>
                                    <div>本次消费：{{item.bcxf}} 元</div>
                                    <div>剩余次数：{{item.sytimes}} 次</div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div style="margin: 10px;">
                                <div v-if="item.sycount < 1">
                                    <div style="color:red;">卡次已消耗完毕，消耗赠卡</div>
                                    <div>到期日期：{{item.enddate}}</div>
                                    <div>上次消费日期：{{item.kfdate}}</div>
                                    <div>上次剩余次数：{{item.zscount}} 次</div>
                                    <div>本次消费：{{item.bcxf}} 元</div>
                                    <div>剩余次数：{{item.sytimes}} 次</div>
                                </div>
                                <div v-else>
                                    <div>到期日期：{{item.enddate}}</div>
                                    <div>上次消费日期：{{item.kfdate}}</div>
                                    <div>上次剩余次数：{{item.sycount}} 次</div>
                                    <div>本次消费：{{item.bcxf}} 元</div>
                                    <div>剩余次数：{{item.sytimes}} 次</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </van-dialog>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue';
    import { Card } from 'vant';
    import { Icon } from 'vant';
    import { Pagination } from 'vant';
    import { Toast } from 'vant'
    import { RadioGroup, Radio } from 'vant';
    import { Picker,Popup } from 'vant';
    import { DatetimePicker } from 'vant';
    import { Progress } from 'vant';
    import { Stepper } from 'vant';
    import { Dialog } from 'vant';
    import { Checkbox, CheckboxGroup } from 'vant';
    import { Overlay } from 'vant';

    Vue.use(Overlay);
    Vue.use(Checkbox);
    Vue.use(CheckboxGroup);
    Vue.use(Dialog);
    Vue.use(Stepper);
    var OSS = require('ali-oss')
    Vue.use(Pagination);
    Vue.use(Icon);
    Vue.use(Card);
    Vue.use(Toast);
    Vue.use(Progress);
    Vue.use(Popup,Picker);
    Vue.use(DatetimePicker);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    var that
    export default {
        name: "ShowKS_Edit",
        data () {
            return {
                showPicker1: false,
                showPicker2: false,
                showPicker3: false,
                showPicker4: false,
                showPicker5: false,
                showPicker6: false,
                showPicker7: false,
                disablesh: false,
                showshqr: false,
                starttime: '',
                endtime: '',
                selectpath: 'od/ks',
                memberinfos: [],
                ksdes: '',
                cdiffull: '0',
                weekid: '',
                orderdate: '',
                currentDate: '',
                tdlist: [],
                tdidlist: [],
                tdinfo: '',
                tdid: '',
                xqlist: [],
                xq: '',
                xqidlist: [],
                xqid: '',
                cdlist: [],
                cd: '',
                cdidlist: [],
                cdid: '',
                kclist: [],
                kc: '',
                kcidlist: [],
                kcid: '',
                bzlist: [],
                bz: '',
                bzidlist: [],
                bzid: '',
                jlid: '',
                jlname: '',
                memberlist: [],
                members: [],
                selmembers: [],
                membersnames: '',
                percentageType: false,
                percentage: 0,
                fileurls: [],
                fileIds: [],
                fileList: [],
                upfileList: [],
                hyimgs: [],
                fylist: [],
                syfylist: [],
                xkprices: 0,
                selno: '',
                vipusers: '',
                normalusers: ''
            }
        },
        mounted() {
            that = this
            that.currentDate = new Date()
            that.getxqlist()
            that.getktlist()
            that.getclasslist()
            var tmpdata = JSON.parse(localStorage.getItem('kssorderinfo'))
            that.selno = tmpdata.orderno
            that.orderdate = tmpdata.orderdate
            that.weekid = tmpdata.weekid
            that.gettdlist(tmpdata.tdid)
            var tmptimearr = tmpdata.ordertime.split('-')
            that.starttime = tmptimearr[0]
            that.endtime = tmptimearr[1]
            that.jlid = tmpdata.jlid
            that.kcid = tmpdata.ktid
            that.getjlbyid(tmpdata.jlid)
            that.getxqlist(tmpdata.xqid)
            that.getcdlist(tmpdata.xqid,tmpdata.cdid)
            that.cdiffull = tmpdata.cdiffull.toString()
            that.getktlist(tmpdata.ktid)
            that.getclasslist(tmpdata.bzid)
            that.getmemberbyids(tmpdata.memberlist)
            that.hyimgs = tmpdata.cimgurl.split(',')
            that.ksdes = tmpdata.orderdes
        },
        methods: {
            checkordercount () {
                var qstr = " orderno = '" + that.selno + "' and orderstate = 2"
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryksorder',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            if (tmpdata.length > 0) {
                                Toast('请不要反复提交，该订单已确认!');
                                that.disablesh = false
                            } else {
                                that.uphycount()
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            gchange () {
                that.members = ''
                that.vipcount = 0
                that.vipusers = 'v'
                that.normalusers = 'n'
                that.fylist = []
                that.xkprices = 0
                for (var i = 0; i < that.selmembers.length; i++) {
                    var tmpdata = that.selmembers[i]
                    if (tmpdata.kctypename.includes('VIP') || tmpdata.kctypename.includes('封闭')) {
                        if (tmpdata.mcount === '-1') {
                            that.vipcount = that.vipcount + 1
                            that.vipusers = that.vipusers + ',' + tmpdata.xyname
                            var  startDate = Date.parse(tmpdata.startdate);
                            var  endDate = Date.parse(tmpdata.enddate);
                            var  jsDate = Date.parse(tmpdata.kfdate);
                            var curDate = Date.parse(new Date())
                            var days= endDate - startDate /// VIP 所有天数
                            var xhdate = jsDate - curDate /// 从上次结算到今天一共经历的天数
                            var dateSpan = Math.abs(days);
                            var iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
                            var dateSpan1 = Math.abs(xhdate);
                            var xhdays = Math.floor(dateSpan1 / (24 * 3600 * 1000));
                            var edayprice = parseInt(tmpdata.sycount) / iDays  //// 每天消耗费用
                            window.console.log(tmpdata.mdes)
                            window.console.log('VIP卡天数' + iDays)
                            window.console.log('上次结算完的费用' + tmpdata.sycount)
                            window.console.log('从上次消费到今天的天数' + xhdays)
                            /// values[i].sycount 为剩余费用，每次都需要更新
                            var sypricetmp =tmpdata.sycount - (edayprice * xhdays)
                            tmpdata.xfdj = edayprice.toFixed(0)
                            tmpdata.xhdays = xhdays.toFixed(0)
                            tmpdata.syfy = sypricetmp.toFixed(0)
                            tmpdata.bcxf = (edayprice * xhdays).toFixed(0)
                            that.fylist.push(edayprice * xhdays)
                            that.xkprices = that.xkprices + edayprice * xhdays
                            that.syfylist.push(sypricetmp)
                        } else {
                            that.normalusers = that.normalusers + ',' + tmpdata.xyname
                            var pricetmp3 = parseInt(tmpdata.mdes / tmpdata.mcount)
                            that.fylist.push(pricetmp3)
                            that.xkprices = that.xkprices + pricetmp3
                            var sypricetmp3 = 0
                            if (tmpdata.sycount < 1) {
                                /// 说明次卡已经消耗完毕，消耗赠卡
                                sypricetmp3 =tmpdata.zscount - 1
                                tmpdata.sytimes = sypricetmp3
                            } else {
                                sypricetmp3 =tmpdata.sycount - 1
                                tmpdata.sytimes = sypricetmp3
                                tmpdata.bcxf = pricetmp3.toFixed(0)
                            }
                            that.syfylist.push(sypricetmp3)
                        }

                    } else if (tmpdata.kctypename.includes('特训')){
                        that.normalusers = that.normalusers + ',' + tmpdata.xyname
                        var pricetmp2 = parseInt(tmpdata.dcprice)
                        that.fylist.push(pricetmp2)
                        that.xkprices = that.xkprices + pricetmp2
                        var sypricetmp2 = 0
                        if (tmpdata.sycount < 1) {
                            /// 说明次卡已经消耗完毕，消耗赠卡
                            sypricetmp2 =tmpdata.zscount - 1
                            tmpdata.sytimes = sypricetmp2
                        } else {
                            sypricetmp2 =tmpdata.sycount - 1
                            tmpdata.sytimes = sypricetmp2
                            tmpdata.bcxf = pricetmp2.toFixed(0)
                        }
                        that.syfylist.push(sypricetmp2)
                    } else {
                        that.normalusers = that.normalusers + ',' + tmpdata.xyname
                        var pricetmp1 = parseInt(tmpdata.mdes / tmpdata.mcount)
                        that.fylist.push(pricetmp1)
                        that.xkprices = that.xkprices + pricetmp1
                        var sypricetmp1 = 0
                        if (tmpdata.sycount < 1) {
                            /// 说明次卡已经消耗完毕，消耗赠卡
                            sypricetmp1 =tmpdata.zscount - 1
                            tmpdata.sytimes = sypricetmp1
                        } else {
                            sypricetmp1 =tmpdata.sycount - 1
                            tmpdata.sytimes = sypricetmp1
                            tmpdata.bcxf = pricetmp1.toFixed(0)
                        }
                        that.syfylist.push(sypricetmp1)
                    }
                    window.console.log('学员费用列表')
                    window.console.log(that.fylist)
                }
                that.allcount = that.selmembers.length
            },
            uporderstate () {
                if (that.selno !== '') {
                    var adata = {
                        orderno: that.selno.toString(),
                        skcounts: that.xkprices.toFixed(0).toString(),
                        orderstate: '2'
                    }
                    window.console.log(adata)
                    that.$http({
                        url: 'https://bcapi.sqnetsoft.cn/upksorderstate',
                        method: 'post',
                        data: adata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            window.console.log(res)
                            if (res.status === 200) {
                                that.disablesh = false
                                Toast('审核完毕!');
                            }
                        })
                        .catch(function (error) {
                            window.console.log('error' + error)
                        })
                } else {
                    Toast('信息不完整!');
                }
            },
            uphycount () {
                if (that.selmembers.length > 0) {
                    var adatas = []
                    var adatas1 = []
                    for (var i = 0; i < that.selmembers.length; i++) {
                        var tmpdata = that.selmembers[i]
                        window.console.log(tmpdata)
                        var adata = ''
                        var adata1 = ''
                        if (tmpdata.kctypename.includes('VIP') || tmpdata.kctypename.includes('封闭')) {
                            window.console.log('vip')
                            window.console.log(tmpdata.memberid)
                            window.console.log(tmpdata.syfy)
                            window.console.log(tmpdata.startdate)
                            window.console.log(tmpdata.zkcount)
                            window.console.log(tmpdata.enddate)
                            window.console.log(tmpdata.xfdj)
                            if (tmpdata.mcount === '-1') {
                                adata = {
                                    memberid: tmpdata.memberid.toString(),
                                    sycount: tmpdata.syfy.toString(),
                                    startdate: tmpdata.startdate.toString(),
                                    enddate: tmpdata.enddate.toString(),
                                    kfdate: that.formatDate(new Date()),
                                    zscount: tmpdata.zscount.toString(),
                                    dcprice: tmpdata.bcxf.toString(),
                                    kctype: tmpdata.kctype.toString()
                                }
                                adata1 = {
                                    tdid: that.tdid.toString(),
                                    jlid: that.jlid.toString(),
                                    mtype: tmpdata.mtype.toString(),
                                    memberid: tmpdata.memberid.toString(),
                                    xsprice: tmpdata.bcxf.toString(),
                                    xsdate: that.orderdate.toString(),
                                    orderno: that.selno.toString(),
                                    kctype: tmpdata.kctype.toString()
                                }
                            } else {
                                if (tmpdata.sycount < 1) {
                                    window.console.log('赠卡')
                                    adata = {
                                        memberid: tmpdata.memberid.toString(),
                                        sycount: '0',
                                        startdate: tmpdata.startdate.toString(),
                                        enddate: tmpdata.enddate.toString(),
                                        kfdate: that.orderdate.toString(),
                                        zscount: tmpdata.sytimes.toString(),
                                        dcprice: '0',
                                        kctype: tmpdata.kctype.toString()
                                    }
                                    adata1 = {
                                        tdid: that.tdid.toString(),
                                        jlid: that.jlid.toString(),
                                        mtype: tmpdata.mtype,
                                        memberid: tmpdata.memberid,
                                        xsprice: '0',
                                        xsdate: that.orderdate.toString(),
                                        orderno: that.selno.toString(),
                                        kctype: tmpdata.kctype.toString()
                                    }
                                } else {
                                    window.console.log('正常卡')
                                    window.console.log(tmpdata.memberid)
                                    window.console.log(tmpdata.sytimes)
                                    window.console.log(tmpdata.startdate)
                                    window.console.log(tmpdata.zscount)
                                    window.console.log(tmpdata.enddate)
                                    window.console.log(tmpdata.bcxf)
                                    adata = {
                                        memberid: tmpdata.memberid.toString(),
                                        sycount: tmpdata.sytimes.toString(),
                                        startdate: tmpdata.startdate.toString(),
                                        enddate: tmpdata.enddate.toString(),
                                        kfdate: that.formatDate(new Date()),
                                        zscount: tmpdata.zscount.toString(),
                                        dcprice: tmpdata.bcxf.toString(),
                                        kctype: tmpdata.kctype.toString()
                                    }
                                    adata1 = {
                                        tdid: that.tdid.toString(),
                                        jlid: that.jlid.toString(),
                                        mtype: tmpdata.mtype,
                                        memberid: tmpdata.memberid,
                                        xsprice: tmpdata.bcxf.toString(),
                                        xsdate: that.orderdate.toString(),
                                        orderno: that.selno.toString(),
                                        kctype: tmpdata.kctype.toString()
                                    }
                                }
                            }

                        } else {
                            window.console.log('非vip')
                            if (tmpdata.sycount < 1) {
                                window.console.log('赠卡')
                                adata = {
                                    memberid: tmpdata.memberid.toString(),
                                    sycount: '0',
                                    startdate: tmpdata.startdate.toString(),
                                    enddate: tmpdata.enddate.toString(),
                                    kfdate: that.orderdate.toString(),
                                    zscount: tmpdata.sytimes.toString(),
                                    dcprice: '0',
                                    kctype: tmpdata.kctype.toString()
                                }
                                adata1 = {
                                    tdid: that.tdid.toString(),
                                    jlid: that.jlid.toString(),
                                    mtype: tmpdata.mtype,
                                    memberid: tmpdata.memberid,
                                    xsprice: '0',
                                    xsdate: that.orderdate.toString(),
                                    orderno: that.selno.toString(),
                                    kctype: tmpdata.kctype.toString()
                                }
                            } else {
                                window.console.log('正常卡')
                                window.console.log(tmpdata.memberid)
                                window.console.log(tmpdata.sytimes)
                                window.console.log(tmpdata.startdate)
                                window.console.log(tmpdata.zscount)
                                window.console.log(tmpdata.enddate)
                                window.console.log(tmpdata.bcxf)
                                adata = {
                                    memberid: tmpdata.memberid.toString(),
                                    sycount: tmpdata.sytimes.toString(),
                                    startdate: tmpdata.startdate.toString(),
                                    enddate: tmpdata.enddate.toString(),
                                    kfdate: that.formatDate(new Date()),
                                    zscount: tmpdata.zscount.toString(),
                                    dcprice: tmpdata.bcxf.toString(),
                                    kctype: tmpdata.kctype.toString()
                                }
                                adata1 = {
                                    tdid: that.tdid.toString(),
                                    jlid: that.jlid.toString(),
                                    mtype: tmpdata.mtype,
                                    memberid: tmpdata.memberid,
                                    xsprice: tmpdata.bcxf.toString(),
                                    xsdate: that.orderdate.toString(),
                                    orderno: that.selno.toString(),
                                    kctype: tmpdata.kctype.toString()
                                }
                            }
                        }
                        adatas.push(adata)
                        adatas1.push(adata1)
                    }
                    that.uphytimes(adatas,adatas1)
                }
            },
            uphytimes (adatas,adata1) {
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/addmcounts',
                    method: 'post',
                    data: adatas,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            window.console.log(res)
                            that.uporderrecs(adata1)
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            uporderrecs (adatas) {
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/addmemberrecs',
                    method: 'post',
                    data: adatas,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.result === 'success') {
                            window.console.log(res)
                            that.uporderstate()
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            shqr () {
                that.showshqr = true
                that.gchange()
            },
            qrshok () {
                that.disablesh = true
                that.checkordercount()
            },
            toggle(index) {
                this.$refs.mycheckboxes[index].toggle()
                window.console.log(this.$refs.mycheckboxes)
            },
            showmembers () {
                that.showPicker7 = true
            },
            gettdlist (tdid) {
                var qstr = " id = '" + tdid + "'"
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/querytd',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            that.tdid = tmpdata[0].id
                            that.tdinfo = tmpdata[0].tdname
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getclasslist (bzid) {
                var qstr = " id = '" + bzid + "'"
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryclass',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            that.bz = tmpdata[0].classname
                            that.bzid = tmpdata[0].id
                            that.getmemberbyclass(that.jlid,that.bzid)
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getjlbyid (jlid) {
                var qstr = "userid = '" + jlid + "'"
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryui',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            that.jlname = tmpdata[0].realname
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getxqlist (xqid) {
                var qstr = " id = '" + xqid + "'"
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryxq',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            that.xq = tmpdata[0].xqname
                            that.xqid = tmpdata[0].id
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getktlist (ktid) {
                var qstr = " id = '" + ktid + "'"
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryctype',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            that.kc = tmpdata[0].kctype
                            that.kcid = tmpdata[0].id
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getcdlist (xqid,cdid) {
                var qstr = 'xqid = ' + xqid
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/querycd',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.cdlist.push(tmpdata[i].cdname)
                                that.cdidlist.push(tmpdata[i].cdid)
                                if (cdid === tmpdata[i].cdid) {
                                    that.cd = tmpdata[i].cdname
                                    that.cdid = tmpdata[i].cdid
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getmemberbyids (members) {
                var qstr = " memberid in (" + members + ") and jlid='" + that.jlid + "' and kctype='" + that.kcid + "'"
                window.console.log(qstr)
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryksmember',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log('会员状态')
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            that.selmembers = tmpdata
                            that.members = ''
                            for (var i = 0; i < tmpdata.length; i++) {
                                if (i === tmpdata.length - 1) {
                                    that.members = that.members + tmpdata[i].xyname
                                } else {
                                    that.members = that.members + tmpdata[i].xyname + ','
                                }
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getmemberbyclass (jlid,classid) {
                var qstr = ''
                if (classid === 0) {
                    qstr = " jlid = '" + jlid + "'"
                } else {
                    qstr = " jlid = '" + jlid + "' and kctype='" + that.kcid + "'"
                }
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryksmember',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            that.memberinfos = tmpdata
                            that.memberlist = tmpdata
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                var daystr = ''
                if(parseInt(date.getDate() + 1) <= 10) {
                    daystr = '0' + parseInt(date.getDate())
                } else {
                    daystr = date.getDate()
                }
                return `${date.getFullYear()}-${monthstr}-${daystr}`;
            },
            ontdConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.showPicker1 = false
                that.tdinfo = value
                that.tdid = that.tdidlist[index]
            },
            onbzConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.showPicker6 = false
                that.bz = value
                that.bzid = that.bzidlist[index]
                that.getmemberbyclass(localStorage.getItem('loginid'),that.bzid)
            },
            onxqConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.showPicker3 = false
                that.xq = value
                that.xqid = that.xqidlist[index]
                that.cdlist = []
                that.cdidlist = []
                that.cd = ''
                that.cdid = ''
                that.getcdlist(that.xqid,'')
            },
            oncdConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.showPicker4 = false
                that.cd = value
                that.cdid = that.cdidlist[index]
            },
            onkcConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.showPicker5 = false
                that.kc = value
                that.kcid = that.kcidlist[index]
            },
            onSJConfirm (date) {
                this.orderdate = this.formatDate(date);
                this.showPicker2 = false;
                var myDate = new Date(date);
                var days = myDate.getDay();
                if (days === 0) {
                    that.weekid = 7
                } else {
                    that.weekid = days
                }

            },
            createOssClient () {
                return new Promise((resolve, reject) => {
                    let client = new OSS({
                        region: 'oss-cn-beijing',
                        accessKeyId: 'LTAI5t9oHyNgxdDwTw4mnHLj',
                        accessKeySecret: 'lFA3kYzgnv7BiAmMVk7NNus7RBcnox',
                        bucket: 'sqpro'
                    })
                    resolve(client)
                    window.console.log(reject)
                }).catch( err =>  {
                    window.console.log(err)
                })
            },
            async fnUploadRequest (option) {
                var that = this
                if (option.file) {
                    window.console.log('单文件')
                    let file = option.file
                    const self = this
                    that.fileList[0].status = 'uploading'
                    that.fileList[0].message = '上传中'
                    this.percentageType = true;
                    return new Promise((resolve, reject) => {
                        let fileName = that.selectpath + '/' + file.name // 文件名字（相对于根目录的路径 + 文件名）
                        // 执行上传
                        window.console.log(fileName)
                        self.createOssClient().then(client => {
                            // 异步上传,返回数据
                            resolve({
                                fileName: file.name,
                                fileUrl: fileName
                            })
                            // 上传处理
                            // 分片上传文件
                            client.multipartUpload(fileName, file, {
                                progress: function (p) {
                                    let e = {}
                                    e.percent = Math.floor(p * 100)
                                    // console.log('Progress: ' + p)
                                    that.percentage = e.percent
                                    window.console.log(e.percent)
                                }
                            }).then((val) => {
                                if (val.res.statusCode === 200) {
                                    that.upfileList.push(val)
                                    window.console.log(that.upfileList)
                                    that.fileurls.push('http://sqpro.oss-cn-beijing.aliyuncs.com/' + fileName)
                                    that.hyimgs = that.fileurls.join(',').toString()
                                    that.fileList[0].status = 'done'
                                    that.fileList[0].message = '上传完成'
                                    return val
                                } else {
                                    that.fileList[0].status = 'failed'
                                    that.fileList[0].message = '上传失败'
                                }
                            }, err => {
                                that.fileList[0].status = 'failed'
                                that.fileList[0].message = '上传失败'
                                window.console.log(err)
                                window.console.log(reject)
                            })
                        }).catch(errinfo => {
                            window.console.log(errinfo)
                        })
                    })
                } else {
                    window.console.log('多文件')
                    for (var i = 0; i < option.length; i++ ) {
                        window.console.log(option)
                        let file = option[i].file
                        window.console.log(that.fileList.length - option.length)
                        that.fileList[i].status = 'uploading'
                        that.fileList[i].message = '上传中'
                        const self = this
                        this.percentageType = true;
                        file.index = i
                        let fileName = that.selectpath + '/' + i + file.name // 文件名字（相对于根目录的路径 + 文件名）
                        // 执行上传
                        self.createOssClient().then(client => {
                            // 上传处理
                            // 分片上传文件
                            client.multipartUpload(fileName, file, {
                                progress: function (p) {
                                    let e = {}
                                    e.percent = Math.floor(p * 100)
                                    // console.log('Progress: ' + p)
                                    that.percentage = e.percent
                                    if (e.percent === 100) {
                                        that.fileList[file.index].status = 'success'
                                        that.fileList[file.index].message = '上传完毕'
                                    }
                                }
                            }).then((val) => {
                                if (val.res.statusCode === 200) {
                                    that.upfileList.push(val)
                                    that.fileurls.push('http://sqpro.oss-cn-beijing.aliyuncs.com/' + fileName)
                                    that.hyimgs = that.fileurls.join(',').toString()
                                    window.console.log(that.upfileList)
                                    return val
                                } else {
                                    window.console.log('上传失败')
                                }
                            }, err => {
                                window.console.log(err)
                            })
                        }).catch(errinfo => {
                            window.console.log(errinfo)
                        })
                    }
                }

            }
        }
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 120px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
