<template>
    <div>
        <div class="van-doc-demo-block">
            <div class="van-doc-demo-block__title">
                会员信息{{hystate}}
            </div>
            <van-field
                    v-model="xyphone"
                    name="手机号"
                    label="手机号"
                    @blur = "checkuserinfo"
                    placeholder="手机号"
                    type="digit"
                    :rules="[{ required: true, message: '请填写手机号' }]"
            />
            <van-field
                    v-model="xyname"
                    name="学员姓名"
                    label="学员姓名"
                    placeholder="学员姓名"
                    :rules="[{ required: true, message: '请填写学员姓名' }]"
            />
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="xytype"
                    label="会员类别"
                    placeholder="点击选择会员类别"
                    @click="showPicker5 = true"
            />
            <van-popup v-model="showPicker5" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="khtypelist"
                        @confirm="onkhtypeConfirm"
                        @cancel="showPicker5 = false"
                />
            </van-popup>
            <van-field name="radio" label="性别">
                <template #input>
                    <van-radio-group v-model="xysex" direction="horizontal">
                        <van-radio name="1">男</van-radio>
                        <van-radio name="2">女</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field
                    readonly
                    clickable
                    name="datetimePicker"
                    :value="xy_orderdate"
                    label="出生日期"
                    placeholder="点击选择时期"
                    @click="showPicker10 = true"
            />
            <van-popup v-model="showPicker10" position="bottom">
                <van-datetime-picker
                        type="date"
                        :min-date="minDate"
                        v-model="currentDate1"
                        @confirm="onCSConfirm"
                        @cancel="showPicker1 = false"
                />
            </van-popup>
            <van-field
                    v-model="xypname"
                    name="监护人"
                    label="监护人"
                    placeholder="监护人"
            />
            <van-field
                    v-model="fzno"
                    name="队服号码"
                    label="队服号码"
                    type="digit"
                    placeholder="请输入期待的队服号码"
            />
            <van-field
                    v-model="fzcm"
                    name="队服尺码"
                    label="队服尺码"
                    placeholder="请输入队服尺码"
            />
            <van-field
                    v-model="fzsg"
                    name="学员身高"
                    label="学员身高"
                    type="digit"
                    placeholder="请输入学员身高"
            />
            <!--
            <van-field
                    v-model="xyage"
                    name="年龄"
                    label="年龄"
                    type="digit"
                    placeholder="请输入队员年龄"
            />
            <van-field
                    name="stepper"
                    label="数量"
                    placeholder="请输入数量"
            >
                <template #input>
                    <van-stepper v-model="fzcount" />
                </template>
            </van-field>
            -->
            <div class="van-doc-demo-block__title">
                业绩信息
            </div>

            <van-field
                    readonly
                    clickable
                    name="datetimePicker"
                    :value="orderdate"
                    label="下单日期"
                    placeholder="点击选择时期"
                    @click="showPicker1 = true"
            />
            <van-popup v-model="showPicker1" position="bottom">
                <van-datetime-picker
                        type="date"
                        v-model="currentDate"
                        @confirm="onSJConfirm"
                        @cancel="showPicker1 = false"
                />
            </van-popup>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="kc"
                    label="课程类型"
                    placeholder="点击选择续费课程类型"
                    @click="showPicker12 = true"
            />
            <van-popup v-model="showPicker12" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="kclist"
                        @confirm="onkcConfirm"
                        @cancel="showPicker12 = false"
                />
            </van-popup>
            <van-field
                    v-model="ccount"
                    name="课次"
                    label="课次"
                    placeholder="课次"
                    :rules="[{ required: true, message: '请填写课次' }]"
            />
            <van-field
                    v-model="cprice"
                    name="金额"
                    label="金额"
                    type="digit"
                    placeholder="金额"
                    :rules="[{ required: true, message: '请填写金额' }]"
            />
            <van-field
                    v-model="zkcount"
                    name="赠课数"
                    label="赠课数"
                    placeholder="赠课数（有赠课的情况）"
            />
            <van-field
                    readonly
                    clickable
                    name="datetimePicker"
                    :value="dq_orderdate"
                    label="到期日期"
                    placeholder="点击选择到期时期"
                    @click="showPicker11 = true"
            />
            <van-popup v-model="showPicker11" position="bottom">
                <van-datetime-picker
                        type="date"
                        v-model="currentDate"
                        @confirm="onDQConfirm"
                        @cancel="showPicker11 = false"
                />
            </van-popup>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="payway"
                    label="支付方式"
                    placeholder="点击选择支付方式"
                    @click="showPicker2 = true"
            />
            <van-popup v-model="showPicker2" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="paywaylist"
                        @confirm="onpaywayConfirm"
                        @cancel="showPicker2 = false"
                />
            </van-popup>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="td"
                    label="教练员团队"
                    placeholder="点击选择团队"
                    @click="showPicker6 = true"
            />
            <van-popup v-model="showPicker6" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="tdlist"
                        @confirm="ontdConfirm"
                        @cancel="showPicker6 = false"
                />
            </van-popup>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="xq"
                    label="所属校区"
                    placeholder="点击选择校区"
                    @click="showPicker3 = true"
            />
            <van-popup v-model="showPicker3" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="xqlist"
                        @confirm="onxqConfirm"
                        @cancel="showPicker3 = false"
                />
            </van-popup>
            <van-field
                    readonly
                    clickable
                    name="picker"
                    :value="jl"
                    label="任课教练"
                    placeholder="点击选择任课教练"
                    @click="showPicker4 = true"
            />
            <van-popup v-model="showPicker4" position="bottom">
                <van-picker
                        show-toolbar
                        :columns="jllist"
                        @confirm="onjlConfirm"
                        @cancel="showPicker4 = false"
                />
            </van-popup>
            <van-field
                    v-model="jxrname"
                    name="介绍人"
                    label="介绍人"
                    placeholder="介绍人"
                    :rules="[{ required: true, message: '请填写介绍人' }]"
            />
            <van-field name="uploader" label="支付凭证">
                <template #input>
                    <van-uploader v-model="fileList" accept="image/*" multiple  :after-read="fnUploadRequest"/>
                </template>
            </van-field>
            <van-progress v-if="percentageType == true" :percentage="percentage" color="#3fb776" style="margin-left: 90px; margin-right:20px; margin-top: 5px; margin-bottom: 5px;"/>
            <van-field
                    v-model="pzimgs"
                    name="凭证地址"
                    label="凭证地址"
                    placeholder="凭证地址"
            />
            <van-field
                    v-model="fkdes"
                    rows="3"
                    autosize
                    label="描述"
                    type="textarea"
                    show-word-limit
                    placeholder="备注"
            />

            <div style="margin: 16px;margin-bottom: 20px;">
                <van-button round block type="info" native-type="submit" @click="tjorder">
                    提交业绩
                </van-button>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Card } from 'vant';
    import { Icon } from 'vant';
    import { Pagination } from 'vant';
    import { Toast } from 'vant'
    import { RadioGroup, Radio } from 'vant';
    import { Picker,Popup } from 'vant';
    import { DatetimePicker } from 'vant';
    import { Progress } from 'vant';
    import { Stepper } from 'vant';

    Vue.use(Stepper);
    var OSS = require('ali-oss')
    Vue.use(Pagination);
    Vue.use(Icon);
    Vue.use(Card);
    Vue.use(Toast);
    Vue.use(Progress);
    Vue.use(Popup,Picker);
    Vue.use(DatetimePicker);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    var that
    export default {
        name: "ShowXD",
        data () {
            return {
                kcread: true,
                kcjeread: true,
                showPicker1: false,
                showPicker2: false,
                showPicker3: false,
                showPicker4: false,
                showPicker5: false,
                showPicker6: false,
                showPicker10: false,
                showPicker11: false,
                showPicker12: false,
                dq_orderdate: '',
                zkcount: 0,
                hystate: '',
                kclist: [],
                kc: '',
                minDate: new Date(2000, 1, 1),
                fzcount: 1,
                dfcount: 1,
                xy_orderdate: '',
                selectpath: 'od/pz',
                tdlist: [],
                td: '',
                xq: '',
                jl: '',
                xyname: '',
                region: 'cn-beijing',
                fkdes: '',
                xysex: '1',
                xypname: '',
                jxrname: '',
                pzimgs: '',
                xyphone: '',
                xytype: '',
                ccount: '',
                cprice: '',
                currentDate1: '',
                payway: '',
                currentDate: '',
                orderdate: '',
                xqlist: [],
                khtypeidlist: [],
                xqid: '',
                tdid: '',
                xqidlist: [],
                percentageType: false,
                jllist: [],
                jlid: '',
                fzno: '',
                fzsg: '',
                xyage: '',
                fzcm: '',
                jlidlist: [],
                fileIds: [],
                fileList: [],
                tdidlist: [],
                upfileList: [],
                paywaylist: [
                    '微信',
                    '支付宝',
                    '考拉',
                    'POS',
                    '转账'
                ],
                paywayid: '',
                khtypelist: [],
                khtype: [],
                khtypeid: '',
                percentage: 0,
                fileurls: [],
                ifnewuser: true,
                hyid: '',
                oldenddate: '',
                kcidlist: [],
                kcid:''
            }
        },
        mounted: function () {
            that = this
            window.console.log(that)
            that.currentDate = new Date()
            that.currentDate1 = new Date('2014-01-01')
            that.getxqlist()
            that.getjllist()
            that.gettdlist()
            that.getktlist()
            that.getmtypelist()
            document.title = '业绩提交'
        },
        methods: {
            adduserinfo () {
                if (that.xyphone !== '' && that.xyname !== '' && that.xysex !== '' && that.xypname !== '' && that.khtypeid !== '') {
                    var adata
                    if (that.ifnewuser) {
                        adata = {
                            id: '',
                            xyname: that.xyname,
                            xypname: that.xypname,
                            phone: that.xyphone,
                            mtype: that.khtypeid.toString(),
                            sex: that.xysex.toString(),
                            age: that.xyage.toString(),
                            birthday: that.xy_orderdate.toString(),
                            adddate: getNowFormatDate()
                        }
                    } else {
                        adata = {
                            id: that.hyid.toString(),
                            xyname: that.xyname,
                            xypname: that.xypname,
                            phone: that.xyphone,
                            mtype: that.khtypeid.toString(),
                            sex: that.xysex.toString(),
                            age: that.xyage.toString(),
                            birthday: that.xy_orderdate.toString(),
                            adddate: getNowFormatDate()
                        }
                    }
                    window.console.log(adata)
                    that.$http({
                        url: 'https://bcapi.sqnetsoft.cn/addmember',
                        method: 'post',
                        data: adata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.result === 'success') {
                                window.console.log(res)
                                if (that.ifnewuser) {
                                    that.hyid = res.data.data
                                }
                                that.addorder()
                            }
                        })
                        .catch(function (error) {
                            window.console.log('error' + error)
                        })
                }
            },
            checkuserinfo () {
                var qstr = "phone = '" + that.xyphone + "'"
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/querymember',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            that.hystate = '(老学员)'
                            var tmpdata = res.data
                            window.console.log(tmpdata)
                            that.ifnewuser = false
                            that.hyid = tmpdata[0].id
                            that.xyname = tmpdata[0].xyname
                            that.xypname = tmpdata[0].xypname
                            that.getmtypelistbyid(tmpdata[0].mtype)
                            that.xysex = tmpdata[0].sex.toString()
                            that.xy_orderdate = tmpdata[0].birthday
                            if (that.xy_orderdate !== '' && that.xy_orderdate !== undefined && that.xy_orderdate !== null) {
                                var datetmp = new Date();
                                var y = datetmp.getFullYear()
                                var datetmp1 = new Date(that.xy_orderdate)
                                var y1 = datetmp1.getFullYear()
                                window.console.log(y)
                                window.console.log(y1)
                                that.xyage = y - y1
                                window.console.log(that.xyage)
                                that.checkxfinfo(that.hyid)
                            }
                        } else {
                            that.hystate = '(新学员)'
                            that.ifnewuser = true
                            that.xyname = ''
                            that.xypname = ''
                            that.xysex = '1'
                            that.xy_orderdate = ''
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            /// 检测续费记录
            checkxfinfo (memberid) {
                var qstr = "memberid = '" + memberid + "'"
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/querymcount',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ' order by enddate desc'
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            window.console.log(tmpdata)
                            that.hystate = '(老学员,到期时间:' + tmpdata[0].enddate + ')'
                            that.oldenddate = tmpdata[0].enddate
                        } else {
                            that.hystate = '(老学员,到期时间:未检测到)'
                            that.oldenddate = ''
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            addxfcount () {
                if (that.ccount !== '' && that.dq_orderdate !== '' && that.zkcount !== '' && that.cprice !== '') {
                    var adata
                    if (that.ccount.toString() === '-1') {
                        adata = {
                            memberid: that.hyid.toString(),
                            sycount: that.cprice.toString(),
                            startdate: that.orderdate,
                            enddate: that.dq_orderdate,
                            kfdate: that.orderdate,
                            zscount: that.zkcount.toString(),
                            dcprice: that.cprice.toString(),
                            kctype: that.kcid.toString()
                        }
                    } else {
                        adata = {
                            memberid: that.hyid.toString(),
                            sycount: that.ccount.toString(),
                            startdate: that.orderdate,
                            enddate: that.dq_orderdate,
                            kfdate: that.orderdate,
                            zscount: that.zkcount.toString(),
                            dcprice: (parseInt(that.cprice) / parseInt(that.ccount)).toFixed(0),
                            kctype: that.kcid.toString()
                        }
                    }
                    window.console.log(adata)
                    that.$http({
                        url: 'https://bcapi.sqnetsoft.cn/addmcount',
                        method: 'post',
                        data: adata,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.result === 'success') {
                                window.console.log(res)
                                Toast('提交订单完毕!');
                                that.$router.push({path: 'MainPage'})
                            }
                        })
                        .catch(function (error) {
                            window.console.log('error' + error)
                        })
                } else {
                    Toast('信息不完整!');
                }

            },
            getmtypelist () {
                var qstr = 'id >= 0'
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/querymembertype',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            that.khtype = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.khtypelist.push(tmpdata[i].mtype)
                                that.khtypeidlist.push(tmpdata[i].id)
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getmtypelistbyid (typeid) {
                var qstr = " id = '" + typeid + "'"
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/querymembertype',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            that.xytype = tmpdata[0].mtype
                            that.khtypeid = tmpdata[0].id
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            gettdlist () {
                var qstr = 'id > 0'
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/querytd',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.tdlist.push(tmpdata[i].tdname)
                                that.tdidlist.push(tmpdata[i].id)
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getxqlist () {
                var qstr = 'id > 0'
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryxq',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.xqlist.push(tmpdata[i].xqname)
                                that.xqidlist.push(tmpdata[i].id)
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getjllist () {
                var qstr = "userid > 0 and postname like '%教练%'"
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryjlinfo',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.jllist.push(tmpdata[i].realname)
                                that.jlidlist.push(tmpdata[i].userid)
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            getktlist () {
                var qstr = 'id > 0'
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/queryctype',
                    method: 'post',
                    data: {
                        whereinfo: qstr,
                        limit: '',
                        sortinfo: ''
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.status === 200) {
                            var tmpdata = res.data
                            for (var i=0;i<tmpdata.length;i++) {
                                that.kclist.push(tmpdata[i].kctype)
                                that.kcidlist.push(tmpdata[i].id)
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            onkcConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.showPicker12 = false
                that.kc = value
                that.kcid = that.kcidlist[index]
            },
            onxqConfirm (value,index) {
                window.console.log(value)
                that.xq = that.xqlist[index]
                window.console.log(that.xq)
                that.xqid = that.xqidlist[index]
                that.showPicker3 = false
            },
            onSJConfirm (date) {
                this.orderdate = this.formatDate(date);
                this.showPicker1 = false;
            },
            onDQConfirm (date) {
                this.dq_orderdate = this.formatDate(date);
                this.showPicker11 = false;
            },
            onCSConfirm (date) {
                this.xy_orderdate = this.formatDate(date);
                var datetmp = new Date();
                var y = datetmp.getFullYear()
                var datetmp1 = new Date(date)
                var y1 = datetmp1.getFullYear()
                window.console.log(y)
                window.console.log(y1)
                that.xyage = y - y1
                window.console.log(that.xyage)
                this.showPicker10 = false;
            },
            onpaywayConfirm (value,index) {
                window.console.log(value)
                that.payway = that.paywaylist[index]
                window.console.log(that.payway)
                that.paywayid = parseInt(index) + 1
                that.showPicker2 = false
            },
            onjlConfirm (value,index) {
                window.console.log(value)
                window.console.log(index)
                that.jl = that.jllist[index]
                window.console.log(that.jl)
                that.jlid = that.jlidlist[index]
                that.showPicker4 = false
            },
            ontdConfirm (value,index) {
                that.td = that.tdlist[index]
                window.console.log(that.td)
                that.tdid = that.tdidlist[index]
                that.showPicker6 = false
            },
            onkhtypeConfirm (value,index) {
                window.console.log(value)
                window.console.log(that.khtypelist)
                that.xytype = that.khtypelist[index]
                window.console.log(that.xytype)
                window.console.log(that.khtype)
                that.ccount = that.khtype[index].mcount
                that.cprice = that.khtype[index].mdes
                if (that.khtype[index].id === 0) {
                    that.kcread = false
                    that.kcjeread = false
                    that.ccount = ''
                    that.cprice = ''
                }
                that.khtypeid = that.khtypeidlist[index]
                that.showPicker5 = false
            },
            tjorder () {
                /// 1、现增加会员
                that.adduserinfo()
            },
            addorder () {
                window.console.log(that.fileIds)
                var adddata = {
                    xyname: that.xyname,
                    xysex: that.xysex.toString(),
                    xypname: that.xypname,
                    xyphone: that.xyphone,
                    ccount: that.ccount.toString(),
                    cprice: that.cprice.toString(),
                    payway: that.payway,
                    tdid: that.tdid.toString(),
                    xqid: that.xqid.toString(),
                    jlid: that.jlid.toString(),
                    jxrname: that.jxrname,
                    xytype: that.khtypeid.toString(),
                    fkimg: that.pzimgs,
                    fkdes: that.fkdes,
                    orderdate: that.orderdate,
                    orderstate: '1',
                    adduser: localStorage.getItem('loginid'),
                    adddate: getNowFormatDate(),
                    rquser: '',
                    rqdate: '',
                    fzno: that.fzno.toString(),
                    fzsg: that.fzsg.toString(),
                    xyage: that.xyage.toString(),
                    fzcount: that.fzcount.toString(),
                    fzcm: that.fzcm.toString(),
                    xyid: that.hyid.toString()
                }
                window.console.log(adddata)
                that.$http({
                    url: 'https://bcapi.sqnetsoft.cn/addorder',
                    method: 'post',
                    data: adddata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log(res)
                        if (res.data.result === 'success') {
                            that.addxfcount()
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) < 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                var daystr = ''
                if(parseInt(date.getDate() + 1) <= 10) {
                    daystr = '0' + parseInt(date.getDate())
                } else {
                    daystr = date.getDate()
                }
                return `${date.getFullYear()}-${monthstr}-${daystr}`;
            },
            createOssClient () {
                return new Promise((resolve, reject) => {
                    let client = new OSS({
                        region: 'oss-cn-beijing',
                        accessKeyId: 'LTAI5t9oHyNgxdDwTw4mnHLj',
                        accessKeySecret: 'lFA3kYzgnv7BiAmMVk7NNus7RBcnox',
                        bucket: 'sqpro'
                    })
                    resolve(client)
                    window.console.log(reject)
                }).catch( err =>  {
                    window.console.log(err)
                })
            },
            async fnUploadRequest (option) {
                var that = this
                if (option.file) {
                    window.console.log('单文件')
                    let file = option.file
                    const self = this
                    that.fileList[0].status = 'uploading'
                    that.fileList[0].message = '上传中'
                    this.percentageType = true;
                    return new Promise((resolve, reject) => {
                        let fileName = that.selectpath + '/' + file.name // 文件名字（相对于根目录的路径 + 文件名）
                        // 执行上传
                        window.console.log(fileName)
                        self.createOssClient().then(client => {
                            // 异步上传,返回数据
                            resolve({
                                fileName: file.name,
                                fileUrl: fileName
                            })
                            // 上传处理
                            // 分片上传文件
                            client.multipartUpload(fileName, file, {
                                progress: function (p) {
                                    let e = {}
                                    e.percent = Math.floor(p * 100)
                                    // console.log('Progress: ' + p)
                                    that.percentage = e.percent
                                    window.console.log(e.percent)
                                }
                            }).then((val) => {
                                if (val.res.statusCode === 200) {
                                    that.upfileList.push(val)
                                    window.console.log(that.upfileList)
                                    that.fileurls.push('http://sqpro.oss-cn-beijing.aliyuncs.com/' + fileName)
                                    that.pzimgs = that.fileurls.join(',').toString()
                                    that.fileList[0].status = 'done'
                                    that.fileList[0].message = '上传完成'
                                    return val
                                } else {
                                    that.fileList[0].status = 'failed'
                                    that.fileList[0].message = '上传失败'
                                }
                            }, err => {
                                that.fileList[0].status = 'failed'
                                that.fileList[0].message = '上传失败'
                                window.console.log(err)
                                window.console.log(reject)
                            })
                        }).catch(errinfo => {
                            window.console.log(errinfo)
                        })
                    })
                } else {
                    window.console.log('多文件')
                    for (var i = 0; i < option.length; i++ ) {
                        window.console.log(option)
                        let file = option[i].file
                        window.console.log(that.fileList.length - option.length)
                        that.fileList[i].status = 'uploading'
                        that.fileList[i].message = '上传中'
                        const self = this
                        this.percentageType = true;
                        file.index = i
                        let fileName = that.selectpath + '/' + i + file.name // 文件名字（相对于根目录的路径 + 文件名）
                        // 执行上传
                        self.createOssClient().then(client => {
                            // 上传处理
                            // 分片上传文件
                            client.multipartUpload(fileName, file, {
                                progress: function (p) {
                                    let e = {}
                                    e.percent = Math.floor(p * 100)
                                    // console.log('Progress: ' + p)
                                    that.percentage = e.percent
                                    if (e.percent === 100) {
                                        that.fileList[file.index].status = 'success'
                                        that.fileList[file.index].message = '上传完毕'
                                    }
                                }
                            }).then((val) => {
                                if (val.res.statusCode === 200) {
                                    that.upfileList.push(val)
                                    that.fileurls.push('http://sqpro.oss-cn-beijing.aliyuncs.com/' + fileName)
                                    that.pzimgs = that.fileurls.join(',').toString()
                                    window.console.log(that.upfileList)
                                    return val
                                } else {
                                    window.console.log('上传失败')
                                }
                            }, err => {
                                window.console.log(err)
                            })
                        }).catch(errinfo => {
                            window.console.log(errinfo)
                        })
                    }
                }

            }
        }
    }
    function getNowFormatDate () {
        var date = new Date()
        var seperator1 = '-'
        var seperator2 = ':'
        var month = date.getMonth() + 1
        var strDate = date.getDate()
        if (month >= 1 && month <= 9) {
            month = '0' + month
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = '0' + strDate
        }
        var hours = date.getHours()
        if (hours >= 0 && hours <= 9) {
            hours = '0' + hours
        }
        var minutes = date.getMinutes()
        if (minutes >= 0 && minutes <= 9) {
            minutes = '0' + minutes
        }
        var seconds = date.getSeconds()
        if (seconds >= 0 && seconds <= 9) {
            seconds = '0' + seconds
        }
        var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate + ' ' + hours + seperator2 + minutes + seperator2 + seconds
        return currentdate
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
