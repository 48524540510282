<template>
    <div>
        <div class="van-doc-demo-block__title">
            收益情况
        </div>
        <div style="padding: 10px;" id="mymainChart" class="chartcard">
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Card } from 'vant';
    import { Icon } from 'vant';
    import { Pagination } from 'vant';
    import { Toast } from 'vant'
    import { RadioGroup, Radio } from 'vant';
    import { Picker,Popup } from 'vant';
    import { DatetimePicker } from 'vant';
    Vue.use(Pagination);
    Vue.use(Icon);
    Vue.use(Card);
    Vue.use(Toast)
    Vue.use(Popup,Picker);
    Vue.use(DatetimePicker);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    var that
    var myChart
    export default {
        name: "MyYJ",
        data () {
            return {
                xllist: ['4.7','4.8','4.9','4.10','4.11','4.12'],
                xsjelist: ['50','80','30','60','180','20']
            }
        },
        mounted() {
            that = this
            window.console.log(that)
            that.drawLine(that.xllist,that.xsjelist)
        },
        methods: {
            drawLine (xllist,xsjelist) {
                // 基于准备好的dom，初始化echarts实例
                myChart = this.$echarts.init(document.getElementById('mymainChart'))
                // 绘制图表
                myChart.setOption({
                    title: {
                        text: '',
                        textStyle:{
                            fontSize:16, //字体大小
                        },
                        subtext: '收益信息'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: ['收益']
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        boundaryGap: [0, 0.01],
                        type: 'category',
                        data: xllist
                    },
                    yAxis: {
                    },
                    series: [
                        {
                            name: '金额',
                            type: 'bar',
                            data: xsjelist
                        }
                    ]
                })
                window.onresize = myChart.resize
            }
        }
    }
</script>

<style scoped>
    .full {
        background-color: #f7f8fa;
    }
    .van-panel__content {
        padding: 10px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 16px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
    .chartcard {
        height: 230px;
        padding: 2px;
    }
</style>
