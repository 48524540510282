import { render, staticRenderFns } from "./MyHoliday.vue?vue&type=template&id=642c0e5a&scoped=true&"
import script from "./MyHoliday.vue?vue&type=script&lang=js&"
export * from "./MyHoliday.vue?vue&type=script&lang=js&"
import style0 from "./MyHoliday.vue?vue&type=style&index=0&id=642c0e5a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642c0e5a",
  null
  
)

export default component.exports