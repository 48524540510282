<template>
    <div style="background-color: #f7f8fa;">
        <van-nav-bar
                title="请假管理"
                left-text="返回"
                left-arrow
                @click-left="returnyw">
            <van-icon name="add-o" slot="right" @click="showaddqj = true"/>
        </van-nav-bar>
        <div class="van-doc-demo-block">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="记录结束"
                        @load="onQJRecLoad"
                >
                    <div>
                        <van-grid :border="false" :column-num="3">
                            <van-grid-item text="讲师姓名" />
                            <van-grid-item text="请假时间" />
                            <van-grid-item text="请假理由" />
                        </van-grid>
                        <div style="width:100%;background-color: #f2f3f5;height:1px;"></div>
                    </div>
                    <div v-for="item in qjlist" :key="item" >
                        <van-grid :border="false" :column-num="3">
                            <van-grid-item :text="item.teachername.String" />
                            <van-grid-item :text="item.addtime.String" />
                            <van-grid-item :text="item.context.String" />
                        </van-grid>
                    </div>
                </van-list>
            </van-pull-refresh>
            <van-popup v-model="showaddqj"  position="bottom"
                       :style="{ height: '80%' }">
                <div class="van-doc-demo-block__title">
                    请假信息
                </div>
                <van-form>
                    <van-field
                            readonly
                            clickable
                            name="calendar"
                            :value="rqvalue"
                            label="请假日期"
                            placeholder="点击选择日期"
                            @click="showCalendar = true"
                    />
                    <van-calendar v-model="showCalendar" @confirm="onRQConfirm" :style="{ height: '500px' }"/>
                    <van-field
                            readonly
                            clickable
                            name="datetimePicker"
                            :value="sjstartvalue"
                            label="开始时间"
                            placeholder="点击选择时间"
                            @click="showPicker1 = true"
                    />
                    <van-popup v-model="showPicker1" position="bottom">
                        <van-datetime-picker
                                type="time"
                                @confirm="onSJConfirm"
                                @cancel="showPicker1 = false"
                        />
                    </van-popup>
                    <van-field
                            readonly
                            clickable
                            name="datetimePicker"
                            :value="sjendvalue"
                            label="结束时间"
                            placeholder="点击结束时间"
                            @click="showPicker2 = true"
                    />
                    <van-popup v-model="showPicker2" position="bottom">
                        <van-datetime-picker
                                type="time"
                                @confirm="onSJEndConfirm"
                                @cancel="showPicker2 = false"
                        />
                    </van-popup>
                    <van-field
                            v-model="qjdes"
                            rows="3"
                            autosize
                            label="描述"
                            type="textarea"
                            show-word-limit
                            placeholder="一句话描述请假内容"
                    />
                    <div style="margin: 16px;">
                        <van-button round block type="info" @click="saveHoliday"  native-type="submit">
                            立即请假
                        </van-button>
                    </div>
                </van-form>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { Form } from 'vant';
    import { Field } from 'vant';
    import { Picker } from 'vant';
    import { Popup } from 'vant';
    import { Toast } from 'vant';
    import { Icon } from 'vant';
    import { Notify } from 'vant';
    import axios from 'axios'
    Vue.prototype.$http = axios
    Vue.use(Notify);
    Vue.use(Icon);
    Vue.use(Popup);
    Vue.use(Picker);
    Vue.use(Field);
    Vue.use(Form);
    Vue.use(Toast);
    var that
    export default {
        name: "MyHoliday",
        data() {
            return {
                rqvalue: '',
                sjstartvalue: '',
                sjendvalue: '',
                showCalendar: false,
                showPicker1: false,
                showPicker2: false,
                qjdes: '',
                showaddqj: false,
                qjlist: []
            };
        },
        mounted: function () {
            that = this
            that.showaddqj = false
            that.$options.methods.checkuserid()
            that.$options.methods.onQJRecLoad();
        },
        methods: {
            checkuserid: function () {
                window.console.log('======= 正在检测userid  ========')
                var tmpdata = JSON.parse(localStorage.getItem('userinfo'))
                that.userimg = tmpdata.headimgurl
                that.usernick = tmpdata.nickname
                if (localStorage.getItem('UserId') === null || localStorage.getItem('UserId') === '0') {
                    window.console.log('======= userid 获取失败  ========')
                    that.$options.methods.checkuser()
                } else {
                    window.console.log('======= userid 已获取  ========')
                    Notify({ type: 'success', message: '您好,' + localStorage.getItem('UserName') });
                }
            },
            checkuser: function () {
                // 依照openid来查看有没有对应用户
                var qstr1 = "openid = '" + localStorage.getItem('openid') + "'"
                that.$http({
                    url: 'https://api.sqnetsoft.cn/member/gbmu',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr1, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data.toString() !== '[]') {
                                var tmpdata = JSON.parse(res.data.data)
                                if (tmpdata[0].id === 0) {
                                    window.console.log('======= userid 获取失败  ========')
                                    localStorage.setItem('UserId',0)
                                    that.$router.push({path: 'Regedit'})
                                } else {
                                    window.console.log('======= 已获取userid  ========')
                                    localStorage.setItem("UserId",tmpdata[0].id.Int64)
                                    localStorage.setItem("UserName",tmpdata[0].parentname.String)
                                    Notify({ type: 'success', message: '您好,' + tmpdata[0].parentname.String });
                                }
                            } else {
                                window.console.log('======= userid 获取失败  ========')
                                localStorage.setItem('UserId',0)
                                that.$router.push({path: 'Regedit'})
                            }
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })

            },
            onQJRecLoad () {
                // var qstr = 'teacherid = 29'
                var qstr = 'teacherid = ' + localStorage.getItem('TeacherId')
                that.$http({
                    url: 'https://api.sqnetsoft.cn/course/qbth',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            window.console.log('已成功获取排课记录')
                            that.qjlist = JSON.parse(res.data.data)
                            window.console.log(that.qjlist)
                            that.refreshing = false
                            that.finished = true
                        }
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            formatDate(date) {
                var monthstr = ''
                if(parseInt(date.getMonth() + 1) <= 10) {
                    monthstr = '0' + parseInt(date.getMonth() + 1)
                } else {
                    monthstr = date.getMonth() + 1
                }
                return `${date.getFullYear()}-${monthstr}-${date.getDate()}`;
            },
            onRQConfirm(date) {
                this.rqvalue = this.formatDate(date);
                this.showCalendar = false;
            },
            onSJConfirm(time) {
                this.sjstartvalue = time;
                this.showPicker1 = false;
            },
            onSJEndConfirm(time) {
                this.sjendvalue = time;
                this.showPicker2 = false;
            },
            saveHoliday(values) {
                window.console.log('submit', values);
                var tmpdata = new Date(this.rqvalue)
                var week = tmpdata.getDay()
                var adddata = {
                    teacherid: parseInt(localStorage.getItem('TeacherId')),
                    plandate: {String: this.rqvalue, Valid: true},
                    weekid: parseInt(week),
                    weektime: {String: this.sjstartvalue + '-' + this.sjendvalue, Valid: true},
                    context: {String: this.qjdes, Valid: true},
                    addtime: {String: getNowFormatDate(), Valid: true},
                    cusid: parseInt(1)
                }
                that.$http({
                    url: 'https://api.sqnetsoft.cn/course/aaubth',
                    method: 'post',
                    data: adddata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            that.showaddqj = false
                            Toast('请假完毕!');
                        }
                        that.$options.methods.onQJRecLoad();
                    })
                    .catch(function (error) {
                        window.console.log('error' + error)
                    })
            },
            returnyw () {
                this.$router.push({path: '/'})
            }

        }
    }
    function getNowFormatDate () {
        var date = new Date()
        var seperator1 = '-'
        var seperator2 = ':'
        var month = date.getMonth() + 1
        var strDate = date.getDate()
        if (month >= 1 && month <= 9) {
            month = '0' + month
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = '0' + strDate
        }
        var hours = date.getHours()
        if (hours >= 0 && hours <= 9) {
            hours = '0' + hours
        }
        var minutes = date.getMinutes()
        if (minutes >= 0 && minutes <= 9) {
            minutes = '0' + minutes
        }
        var seconds = date.getSeconds()
        if (seconds >= 0 && seconds <= 9) {
            seconds = '0' + seconds
        }
        var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate + ' ' + hours + seperator2 + minutes + seperator2 + seconds
        return currentdate
    }
</script>

<style scoped>
    body {
        min-width: 1100px;
        margin: 0;
        overflow-x: auto;
        color: #323233;
        font-size: 16px;
        font-family: PingFang SC, 'Helvetica Neue', Arial, sans-serif;
        background-color: #f7f8fa;
        -webkit-font-smoothing: antialiased;
    }
    .van-doc-demo-block:first-of-type .van-doc-demo-block__title {
        padding-top: 20px;
    }
    .van-doc-demo-block__title {
        display: flex;
        justify-items: flex-start;
        margin: 0;
        padding: 32px 16px 16px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
    }
</style>
